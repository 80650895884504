import { CarouselProgram } from "@/dashboard/components/layout/Corps";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
const Dashboard = () => {
  const steps = [
    {
      target: "html",
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">Découvrez votre tableau de bord Verdeo</h2>
          <p className="text-white/80">Un tour rapide pour vous familiariser avec votre tableau de bord et ses fonctionnalités.</p>
        </div>
      ),
      placement: "center",
      disableBeacon: true, // Démarre automatiquement
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#mes-programmes", // Sidebar
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">Ici, vous pouvez accéder à vos programmes et projets.</h2>
        </div>
      ),
      placement: "right",
      disableBeacon: true, // Démarre automatiquement
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#add-program",
      title: t("Ajoutez un nouveau programme"),
      content: (
        <div className="text-center">
          <p>{t("Cliquez ici pour ajouter un nouveau programme.")}</p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#mes-programs",
      title: t("Vos programmes"),
      content: (
        <div className="text-center">
          <p>{t("Ici, vous trouverez tous vos programmes en cours. Cliquez sur un programme pour en savoir plus.")}</p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#suivi-financement",
      title: t("Suivi du financement"),
      content: (
        <div className="text-center">
          <p>{t("Cette barre vous montre l'état des fonds demandés par rapport au total des projets.")}</p>
        </div>
      ),
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#repartition-fond",
      title: t("Répartition des fonds"),
      content: (
        <div className="text-center">
          <p>{t("Visualisez le pourcentage de financement déjà obtenu pour chaque type de projet")}</p>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#data-reporting",
      title: t("Indicateurs"),
      content: (
        <div className="text-center">
          <p>{t("Vue d'ensemble de vos indicateurs")}</p>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    },
    {
      target: "#data-reporting",
      title: t("Vue géographique des projets"),
      content: (
        <div className="text-center">
          <p>{t("Cliquez ici pour voir vos projets sur une carte.")}</p>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true, // Désactive l’interaction avec l’arrière-plan
    }
  ];
  const [run, setRun] = useState(false);

  useEffect(() => {
    // Vérifie si l'utilisateur a déjà vu le tutoriel
    const hasSeenTutorial = localStorage.getItem("hasSeenTutorial");
    if (!hasSeenTutorial) {
      setRun(true);
    }
  }, []);

   const handleJoyrideCallback = (data: CallBackProps) => {
    const { status , action} = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    
    if (finishedStatuses.includes(status) || action === "skip") {
      setRun(false);
      localStorage.setItem("hasSeenTutorial", "true"); // Enregistre que l'utilisateur a terminé le tutoriel
    }
  };
  
  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous={false}
        scrollToFirstStep={false}
        disableScrolling={true}
        showProgress={true}
        showSkipButton={true}
        spotlightClicks={false}
        locale={{
          back: "Précédent",
          close: "Suivant",
          last: "Terminer",
          next: "Suivant",
          skip: "Passer",
        }}
      
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#80B533",
            overlayColor: "rgba(255, 255, 255, 0.8)",
            spotlightShadow: "0 0 0 rgba(0, 0, 0, 0)",
            textColor: "#fff",
            zIndex: 1000,
          },
          buttonBack: {
            backgroundColor: '#669523',
            color: 'white',
          },
          
          buttonNext: {
            backgroundColor: '#669523',
            color: 'white',
          },
          
        }}
      />
      <h1 className="mb-5 text-3xl font-medium">Tableau de bord</h1>
      <h2 className="text-xl font-semibold text-[#626262] inline-block" id="mes-programs">Mes Programmes</h2>
      <CarouselProgram />
    </>
  );
};

export default Dashboard;
