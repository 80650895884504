import { useFetchData } from "@/hooks/useFetchData";
import { CategoryProjectType } from "@/types";
import { createContext, ReactNode, useContext } from "react";

export interface TypeProjectContextType {
  typesProject: CategoryProjectType[] | null;
}

const TypeProjectContext = createContext<TypeProjectContextType | undefined>(
  undefined
);

export const TypeProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data } = useFetchData(
    `${import.meta.env.VITE_API_ENDPOINT}/project-types`
  );
  // Vérifier si 'data' est un tableau avant de le passer au contexte
  let typesProject =
    typeof data == "object" && data ? data["hydra:member"] : null;

  if (typesProject) {
    typesProject = typesProject.filter((tp) => {
      return tp.name !== 'Personnalisé'
    })
    localStorage.setItem("typesProject", JSON.stringify(typesProject));
  }


  return (
    <TypeProjectContext.Provider value={{ typesProject }}>
      {children}
    </TypeProjectContext.Provider>
  );
};

// Custom hook pour utiliser le contexte
// eslint-disable-next-line react-refresh/only-export-components
export const useTypeProjectContext = (): TypeProjectContextType => {
  const context = useContext(TypeProjectContext);
  if (!context) {
    throw new Error(
      "useTypeProjectContext doit être utilisé à l'intérieur d'un TypeProjectProvider"
    );
  }
  
  if(!context.typesProject){
    return {
      typesProject: JSON.parse(localStorage.getItem("typesProject") || "[]")
    }
  }

  return context;
};

export default TypeProjectContext;
