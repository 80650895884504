import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { projectStatus } from "@/constants/constants";
import usePostData from "@/hooks/usePostData";
import { cn } from "@/libs/utils";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ProgramType } from "@/types/dashboard";

type ProjectSummaryType = {
  id: number;
  title: string;
  picture?: string;
  amount?: string;
  type?: string;
  link: string;
  className?: string;
  status: number;
  typeProjectImage: string | null;
  programsList: ProgramType;
  programId: number;
  onSelect: () => void;
};
const ProjectSummary: FC<ProjectSummaryType> = ({
  id,
  title,
  picture,
  type,
  link,
  className,
  status,
  typeProjectImage,
  programsList,
  programId,
  onSelect,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openDeplacer, setOpenDeplacer] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const { postData } = usePostData<any>();
  const { toast } = useToast();
  const [programSelected, setProgramSelected] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingDeplacer, setLoadingDeplacer] = useState(true);
  // const { typesProject } = useTypeProjectContext();
  // const typeProject = typesProject?.find((t) => {
  //   const slug = slugify(t.name, {
  //     lower: true,
  //     remove: /[*+~.()'"!:@]/g,
  //   });

  //   return slug === type;
  // });

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  const getListPrograms = async () => {
    
  };

  return (
    <>
      <div>
        <Link
          onClick={(e) => {
            e.preventDefault();
            onSelect();
          }}
          to={link}
          className={cn(
            "aspect-[250/200] h-[170px] rounded-[15px] overflow-hidden relative flex w-full",
            className
          )}
        >
          <div className="absolute left-0 top-0 size-full bg-slate-700">
            {picture ? (
              <img
                src={picture}
                alt={title}
                className="size-full object-cover"
              />
            ) : typeProjectImage ? (
              <img
                src={import.meta.env.VITE_MEDIA_BASE + typeProjectImage}
                alt="image en attente"
                className="size-full object-cover"
              />
            ) : (
              <img
                src="/pexels-pixabay-235925.jpg"
                alt="image en attente"
                className="size-full object-cover"
              />
            )}
          </div>
          <Status status={status} />
          <img
            src="/folder.svg"
            alt="picto folder"
            className="absolute left-3 top-4 w-5"
          />
          <div className="absolute right-4 top-4">
            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <DropdownMenuTrigger asChild className="cursor-pointer">
                <svg
                  className="float-right w-6 cursor-pointer text-white [text-shadow:_1px_1px_3px_rgb(0_0_0_/_80%)]"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <circle cx="12" cy="12" r="1" />{" "}
                  <circle cx="12" cy="5" r="1" />{" "}
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(false); 
                      setTimeout(() => setOpenDuplicate(true), 0); 
                    }}
                  >
                    <span>{t("Dupliquer")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(false); 
                      setTimeout(() => setOpenDelete(true), 0); 
                    }}
                  >
                    <span>{t("Supprimer")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                    onPointerDown={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(false); 
                      setTimeout(() => setOpenDeplacer(true), 0); 
                      getListPrograms();
                    }}
                    onSelect={(e) => e.preventDefault()}
                  >
                    <span>Déplacer</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Link>
        <div className="flex flex-col justify-end pt-4">
          <h2
            title={title}
            className="line-clamp-2 text-base font-normal leading-5"
          >
            <Link
              to={link}
              onClick={(e) => {
                e.preventDefault();
                onSelect();
              }}
            >
              {title}
            </Link>
          </h2>
        </div>
      </div>
      <Dialog open={openDuplicate} onOpenChange={setOpenDuplicate}>
        <DialogContent className="sm:max-w-[600px]">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setLoadingDuplicate(true);
              const res = await postData({
                url: `${
                  import.meta.env.VITE_IP_API
                }/api/project/duplicate/${id}`,
                data: {},
              });

              if (
                res === null ||
                res.success === undefined ||
                res.success === null ||
                !res.success
              ) {
                toast({
                  className: "text-red-500",
                  title: t(
                    "Une erreur est survenue, le projet n'a pas été dupliqué."
                  ),
                  duration: 1000
                });
                setLoadingDuplicate(false);
                setOpenDuplicate(false);
                return;
              }

              toast({
                title: t("Le projet a bien été dupliqué."),
                duration: 1000
              });
              setLoadingDuplicate(false);
              setOpenDuplicate(false);
              window.location.reload();
            }}
            className="space-y-6"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {t("Dupliquer un projet")}
              </DialogTitle>
              <DialogDescription>
                {t("Voulez-vous vraiment dupliquer ce projet ?")}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button type="submit" disabled={loadingDuplicate}>
                {t("Oui")}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenDuplicate(false);
                }}
                className="bg-slate-500"
              >
                {t("Annuler")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDelete} onOpenChange={setOpenDelete}>
        <DialogContent className="sm:max-w-[600px]">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setLoadingDelete(true);
              const res = await postData({
                url: `${import.meta.env.VITE_IP_API}/api/project/delete/${id}`,
                data: {},
              });

              if (
                res === null ||
                res.success === undefined ||
                res.success === null ||
                !res.success
              ) {
                toast({
                  className: "text-red-500",
                  title: t(
                    "Une erreur est survenue, le projet n'a pas été supprimé."
                  ),
                  duration: 1000
                });
                setLoadingDelete(false);
                setOpenDelete(false);
                return;
              }

              toast({
                title: t("Le projet a bien été supprimé."),
                duration: 1000
              });
              setLoadingDelete(false);
              setOpenDelete(false);
              window.location.reload();
            }}
            className="space-y-6"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {t("Supprimer un projet")}
              </DialogTitle>
              <DialogDescription>
                {t("Voulez-vous vraiment supprimer ce projet ?")}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button type="submit" disabled={loadingDelete}>
                {t("Oui")}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenDelete(false);
                }}
                className="bg-slate-500"
              >
                {t("Annuler")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeplacer} onOpenChange={setOpenDeplacer}>
      <DialogContent className="max-w-6xl">
          <form
            onSubmit={async (e) =>
              {
                e.preventDefault();
                setLoadingDeplacer(true);
                const res = await postData({
                  url: `${import.meta.env.VITE_IP_API}/api/project/move/${id}`,
                  data: {
                    newProgramId: programSelected,
                  },
                });

                if (
                  res === null ||
                  res.success === undefined ||
                  res.success === null ||
                  !res.success
                ) {
                  toast({
                    className: "text-red-500",
                    title: "Une erreur est survenue, le projet n'a pas été déplacé.",
                    duration: 1000
                  });
                  setLoadingDeplacer(false);
                  setOpenDeplacer(false);
                  return;
                } else {
                  setLoadingDeplacer(false);
                  setOpenDeplacer(false);
                  toast({
                    title: "Le projet a bien été déplacé.",
                    duration: 2000
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                  // window.location.reload();
                }
              }
            }
          >
            <DialogHeader className="justify-around">
            <DialogTitle asChild>
              <h2 className="mb-2 mt-5 flex items-center justify-center gap-4 text-center text-xl font-bold">
                Sélectionner le programme pour y déplacer le projet.
              </h2>
            </DialogTitle>
            <DialogDescription asChild className="text-[#595959]">
              
                {/* <p className="mb-0  text-center sm:mx-auto sm:max-w-[60vw]">
                Un programme regroupe plusieurs projets alignés sur un objectif commun. Il assure la coordination stratégique et garantit la cohérence des actions tout en laissant aux projets une autonomie adaptée à leur contexte local.
                </p> */}
            </DialogDescription>
          </DialogHeader>
          
            <ScrollArea style={{height: 'calc(100vh - 230px)'}}>
            <div className={`mt-8 flex flex-wrap items-start pb-52`}>
                
                {programsList?.filter((programStated) => programStated.id !== programId)
                  .map((programStated:ProgramType, index: number) => {
                  return (
                  <div key={index} className={`cursor-pointer relative mb-4 w-1/2 min-w-52 flex-1 px-4 md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%]`} title={programStated.name} onClick={() => {setProgramSelected(programStated.id); setLoadingDeplacer(false)}}>
                    <div className={`group relative flex aspect-[250/200] overflow-hidden  rounded-[15px] bg-foreground ${programSelected == programStated.id ? 'border-[#9dc95f]' : ''}`} style={{borderWidth: '2px'}}>
                      {programStated.cover && (
                        <img
                          src={`${
                            import.meta.env.VITE_MEDIA_BASE + programStated.cover.uri
                          }`}
                          alt="Card"
                          className="m-auto size-full object-cover opacity-80 transition-all group-hover:scale-110 group-hover:opacity-100"
                        />
                      )}
                      <svg
                        className="absolute left-3 top-3 size-6"
                        width="60"
                        height="60"
                        viewBox="0 0 27 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 20C2.3125 20 1.72417 19.7554 1.235 19.2663C0.745 18.7763 0.5 18.1875 0.5 17.5V2.5C0.5 1.8125 0.745 1.22417 1.235 0.735C1.72417 0.245 2.3125 0 3 0H9.46875C9.80208 0 10.12 0.0625001 10.4225 0.1875C10.7242 0.3125 10.9896 0.489583 11.2188 0.71875L13 2.5H23C23.6875 2.5 24.2763 2.745 24.7663 3.235C25.2554 3.72417 25.5 4.3125 25.5 5H11.9688L9.46875 2.5H3V17.5L5.46875 9.28125C5.63542 8.73958 5.94292 8.30708 6.39125 7.98375C6.83875 7.66125 7.33333 7.5 7.875 7.5H24C24.8542 7.5 25.5263 7.83833 26.0163 8.515C26.5054 9.1925 26.6354 9.92708 26.4062 10.7188L24.1562 18.2188C23.9896 18.7604 23.6825 19.1929 23.235 19.5163C22.7867 19.8388 22.2917 20 21.75 20H3ZM5.625 17.5H21.75L24 10H7.875L5.625 17.5ZM5.625 17.5L7.875 10L5.625 17.5ZM3 7.5V2.5V7.5Z"
                          fill="white"
                        />
                      </svg>

                      <div className="absolute right-4 top-4">
                        {programSelected != programStated.id && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle text-white font-bold"><circle cx="12" cy="12" r="10"/></svg>}
                        {programSelected == programStated.id && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-check-big text-[#9dc95f]"><path d="M21.801 10A10 10 0 1 1 17 3.335"/><path d="m9 11 3 3L22 4"/></svg>}
                      </div>
                    </div>
                    <div className="pointer-events-none absolute inset-x-4 bottom-0 flex flex-wrap items-start px-4 py-3">
                      <h2 className="line-clamp-2 text-[16px] font-semibold leading-5 text-background text-ellipsis">
                        {programStated.name}
                      </h2>
                    </div>
                  </div> )
                })
                } 
              </div>
            </ScrollArea>
            
            <div className="from-background to-background/0 absolute bottom-0 left-0 z-50 flex w-full justify-center bg-white py-8">
              <Button type="submit"  disabled={loadingDeplacer}>Déplacer le projet</Button>
            </div>
            
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Status: FC<{ status: number }> = ({ status }) => {
  let statusText = "";
  let bg = "background";
  let text = "foreground";

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  switch (status) {
    case projectStatus.NotStarted:
      statusText = t("Non commencé");
      bg = "background";
      text = "foreground";
      break;
    case projectStatus.InProgress:
      statusText = t("En cours");
      bg = "[#FF9500]";
      text = "background";
      break;
    case projectStatus.AwaitingValidation:
      statusText = t("En attente de validation");
      bg = "[#2710D8]";
      text = "background";
      break;
    case projectStatus.Validated:
      statusText = t("Validé");
      bg = "[#00973C]";
      text = "background";
      break;
    case projectStatus.Rejected:
      statusText = t("Refusé");
      bg = "[#CE3030]";
      text = "background";
      break;
    default:
      break;
  }
  return (
    <>
      <div
        hidden
        className="bg-[#00973C] bg-[#2710D8] bg-[#CE3030] bg-[#FF9500]"
      ></div>
      <div
        className={`bg-${bg} text-${text} absolute bottom-3 left-3 z-10 rounded-full px-2 py-1 text-xs`}
      >
        {statusText}
      </div>
    </>
  );
};
export default ProjectSummary;
