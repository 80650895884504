import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { Button } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import ProjectList from "@/dashboard/components/project/ProjectList";
import { useModal } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { useDatabase } from "@/programs/hooks/useDatabase";
import {  FormEvent, Suspense, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProject from "./AddProject";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import MapClusterPrograms from "@/dashboard/components/program/MapClusterPrograms";
import { Edit } from "lucide-react";
import { Sheet,  SheetContent, SheetDescription,  SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Checkbox } from "@/components/ui/checkbox";
import ErrorBoundary from "@/components/errors/ErrorBoundary";
import ChartItemGlobal from "@/dashboard/components/project/components/ChartItemGlobal";
import { Indicator } from "@/types/dashboard";
import { calculReporting } from "@/libs/utils";
import StatCardColoredDashboard from "@/dashboard/components/project/components/StatCardColoredDashboard";
import { t } from "i18next";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

const DashboardProgram = () => {
  const { setOpenModal } = useModal();
  const { updateProgramInDB } = useDatabase();
  const { id } = useParams<{ id: string }>();
  const [projectSelected, setProjectSelected] = useState<{name:string;id:number}[]>([]);
  const navigate = useNavigate();
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const programs = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`,
    'programs',
    1000 * 60 * 60
  );
  const { data, loading, error } = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/report/programs?program=${id}`,
    `report-prog-${id}`,
    1000 * 60 * 60
  );
  const steps: Step[] = [
    {
      target: "body", // Étape de bienvenue sans ciblage spécifique
      content: (
        <div className="text-center">
          <h2 className="text-xl font-bold mb-4">{t("Bienvenue sur la fiche de programme !")}</h2>
          <p>{t("Ici, vous pouvez voir un aperçu global des statistiques et des projets en cours.")}</p>
        </div>
      ),
      placement: "center",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#add-project", // Bouton "Ajouter un projet"
      content: (
        <div className="text-center">
          <p>{t("Cliquez ici pour ajouter un nouveau projet.")}</p>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#program-title", // Titre du programme
      content: (
        <div className="text-center">
          <h2 className="text-xl font-bold mb-4">{t("Voici le programme en cours")}</h2>
          <p>{t("Vous pouvez consulter ses détails ci-dessous.")}</p>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#projects-lists", // Cartes des projets
      content: (
        <div className="text-center">
          <p>{t("Chaque carte représente un projet dans votre programme.")}</p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: ".edit-selection-btn", // Modifier la sélection
      content: (
        <div className="text-center">
          <p>{t("Modifiez les projets sélectionnés avec ce bouton.")}</p>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#project-cards", // Graphique des objectifs
      content: (
        <div className="text-center">
          <p>{t("Chaque carte représente une activité dans votre programme.")}</p>
        </div>
      ),
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#objectives-chart", // Graphique des objectifs
      content: (
        <div className="text-center">
          <p>{t("Suivez la progression des objectifs de votre programme ici.")}</p>
        </div>
      ),
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true,
    },
  ];
  const [run, setRun] = useState(false);

  useEffect(() => {
    // Vérifie si l'utilisateur a déjà vu le tutoriel
    const hasSeenTutorial = localStorage.getItem("hasSeenTutorialProgram");
    if (!hasSeenTutorial && !programs.loading) {
      setRun(true);
    }
  }, [programs.loading]);

   const handleJoyrideCallback = (data: CallBackProps) => {
    const { status , action} = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    
    if (finishedStatuses.includes(status) || action === "skip") {
      setRun(false);
      localStorage.setItem("hasSeenTutorialProgram", "true"); // Enregistre que l'utilisateur a terminé le tutoriel
    }
  };
  const minDate = data?.programs[0]?.minDate;
  const maxDate = data?.programs[0]?.maxdate;

  const dataReporting = useMemo(() => {
    if(!data) return [];
    return calculReporting(data, null, projectSelected)
  }, [data, projectSelected]);

  const indicatorEstimateModif = useMemo(() => {
    if (!data?.programs?.[0]?.projects) return [];
    const selectedProjects = data.programs[0].projects.filter(project => 
      projectSelected.some(selectedProj => selectedProj.id === project.id)
    );
    
    // Pour chaque projet, on récupère les indicateurs de type 1
    const allIndicators = selectedProjects.flatMap(project =>
      project.projectIndicators
        .filter(indDefVal => indDefVal.type === 1)
        .map(indDefVal => ({
          idProject: project.id,
          name: indDefVal.indicator.name,
          idIndicator: indDefVal.indicator.id,
        }))
    );
  
    // On regroupe par id en additionnant les estimates
    const groupedIndicators = allIndicators.reduce((acc, curr) => {
      const key = `${curr.idProject}-${curr.idIndicator}`;
      if (!acc[key]) {
        acc[key] = { ...curr, count: 1 };
      } else {
        acc[key].count += 1;
      }
      return acc;
    }, {});
    // Retourne un tableau d'objets groupés
    return Object.values(groupedIndicators);
  }, [data, projectSelected]);
  const indicatorEstimateDefaultValue = useMemo(() => {
    if (!data?.programs?.[0]?.projects) return [];
    const selectedProjects = data.programs[0].projects.filter(project => 
      projectSelected.some(selectedProj => selectedProj.id === project.id)
    );
    // On récupère pour chaque projet un tableau d'objets { id, estimated }
    const allIndicators = selectedProjects.flatMap(project => {
      const expectedDuration = project.expectedDuration;
      const proj = indicatorEstimateModif.find((item) => item.idProject === project.id);
      return project.projectIndicatorDefaultValues.map(indDefVal => ({
        id: indDefVal.indicator.id,
        name: indDefVal.indicator.name,
        idProject: project.id,
        estimated: (indDefVal.value * expectedDuration) - (proj?.count ? proj?.count * indDefVal.value : 0),
      }));
    });
  
    // On regroupe par id en additionnant les estimates
    const groupedIndicators = allIndicators.reduce((acc, curr) => {
      if (acc[curr.id]) {
        acc[curr.id].estimated += curr.estimated;
      } else {
        acc[curr.id] = { ...curr };
      }
      return acc;
    }, {});
    
    // Retourne un tableau d'objets groupés
    return Object.values(groupedIndicators);
  }, [data,projectSelected]);
  
  const indicatorEstimateValue = useMemo(() => {
    if (!data?.programs?.[0]?.projects) return [];
    const selectedProjects = data.programs[0].projects.filter(project => 
      projectSelected.some(selectedProj => selectedProj.id === project.id)
    );
    // Pour chaque projet, on récupère les indicateurs de type 1
    const allIndicators = selectedProjects.flatMap(project =>
      project.projectIndicators
        .filter(indDefVal => indDefVal.type === 1)
        .map(indDefVal => ({
          id: indDefVal.indicator.id,
          name: indDefVal.indicator.name,
          estimated: indDefVal.value,
        }))
    );
  
    // On regroupe par id en additionnant les estimates
    const groupedIndicators = allIndicators.reduce((acc, curr) => {
      if (acc[curr.id]) {
        acc[curr.id].estimated += curr.estimated;
      } else {
        acc[curr.id] = { ...curr };
      }
      return acc;
    }, {});
  
    // Retourne un tableau d'objets groupés
    return Object.values(groupedIndicators);
  }, [data, projectSelected]);

  const indicatorsvalue = useMemo(() => {
    if (!data?.programs?.[0]?.projects) return [];
    const selectedProjects = data.programs[0].projects.filter(project => 
      projectSelected.some(selectedProj => selectedProj.id === project.id)
    );
    // Pour chaque projet, on récupère les indicateurs de type 1
    const allIndicators = selectedProjects.flatMap(project =>
      project.projectIndicators
        .filter(indDefVal => indDefVal.type === 1)
        .map(indDefVal => ({
          id: indDefVal.indicator.id,
          name: indDefVal.indicator.name,
          value: indDefVal.value,
        }))
    );
  
    // On regroupe par id en additionnant les estimates
    const groupedIndicators = allIndicators.reduce((acc, curr) => {
      if (acc[curr.id]) {
        acc[curr.id].value += curr.value;
      } else {
        acc[curr.id] = { ...curr };
      }
      return acc;
    }, {});
  
    // Retourne un tableau d'objets groupés
    return Object.values(groupedIndicators);
  }, [data, projectSelected]);
  const indicators = useMemo(() => {
    if (!data?.programs?.[0]?.projects) return [];
  
    // Convertir les listes en objets indexés par ID pour accès rapide
    const defaultValuesMap = indicatorEstimateDefaultValue.reduce((acc, curr) => {
      acc[curr.id] = curr.estimated;
      return acc;
    }, {});
  
    const modifiedValuesMap = indicatorEstimateValue.reduce((acc, curr) => {
      acc[curr.id] = curr.estimated;
      return acc;
    }, {});
  
    // Calcul des valeurs finales
    const finalIndicators = indicatorEstimateDefaultValue.map(defaultIndicator => {
      const id = defaultIndicator.id;
      const defaultValue = defaultValuesMap[id] || 0;  // Valeur par défaut de cet indicateur
      const name = defaultIndicator.name;
      const newValue = modifiedValuesMap[id];           // Nouvelle valeur (si modifiée)
  
      // Si une nouvelle valeur est présente, on applique la soustraction et l'ajout
      const finalValue = newValue !== undefined 
        ? (defaultValue - defaultIndicator.estimated) + newValue 
        : defaultIndicator.estimated;
      
      const totalYear = maxDate - minDate
      
      return {
        id,
        name,
        totalYear,
        realized: indicatorsvalue.find((indicator) => indicator.id == id) ? indicatorsvalue.find((indicator) => indicator.id == id).value : 0,
        estimated: finalValue,
      };
    });
  
    return finalIndicators;
  }, [indicatorEstimateDefaultValue, indicatorEstimateValue]);
  const [reloadData, setReloadData] = useState(false);
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    projectsTemp: any,
    projectsTempBdd: any
  ) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setReloadData(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsArray: any[] = [];
    projectsTemp.map((project: any) => {
      if (project.type) {
        projectsArray.push({
          name: project.name,
          type: project.type,
        });
      }
    });
    const projectsArrayBdd: any[] = [];
    projectsTempBdd?.map((project: any) => {
      if (project.type) {
        projectsArrayBdd.push({
          name: project.name,
          type: project.type,
        });
      }
    });

    const program = await updateProgramInDB(
      { projects: [...projectsArrayBdd] },
      Number(id)
    );
    toast({
      title: "Vos informations sont bien prises en compte.",
    });
    setOpenModal({ name: "project", value: false });

    navigate(
      "/dashboard/project/" + program?.projects[program?.projects.length - 1].id
    );
  };
 
  const program = programs?.data?.find((item) => item.id == id);
  const programWithProjectFiltered = {
    name: program?.name || '', 
    projects: program?.projects.filter(project => 
      projectSelected.some(selectedProj => selectedProj.id === project.id)
    )
  };
  useEffect(() => {
    if (!program) {
      toast({ title: "Le programme demandé n'a pas été trouvé." });
    }
    if(program?.projects.length > 0) {
      setProjectSelected(program?.projects?.map(project => ({ name: project.name, id: project.id })))
    }
  },[program])
  const handleChangeProjectSelected = ({name, id}: {name: string; id: number}) => {
    if(name === 'all') {
      if(program?.projects.length === projectSelected.length) {
        // Si tous les projets sont déjà sélectionnés, on les désélectionne tous
        setProjectSelected([]);
      } else {
        // Sinon, on sélectionne tous les projets
        setProjectSelected(program?.projects?.map(project => ({ name: project.name, id: project.id })));
      }
    } else {
      // Pour chaque projet, on vérifie si il est déjà sélectionné
      setProjectSelected((prev) => {
        return prev.some(item => item.id === id)
          ? prev.filter((item) => item.id !== id) // Si déjà sélectionné, on le retire
          : [...prev, {name: name, id: id}]; // Sinon, on l'ajoute
      });
    }
  };
  
  const isChecked = ({name, id}: {name: string; id: number}) => 
    projectSelected.some((project) => project.name === name && project.id === id);
  
  programs.error && new Error("Une erreur est survenue: " + programs.error);
  
  return (
    <div>
      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous={false}
        scrollToFirstStep={false}
        disableScrolling={true}
        showProgress={true}
        showSkipButton={true}
        spotlightClicks={false}
        locale={{
          back: "Précédent",
          close: "Suivant",
          last: "Terminer",
          next: "Suivant",
          skip: "Passer",
        }}
      
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#80B533",
            overlayColor: "rgba(255, 255, 255, 0.8)",
            spotlightShadow: "0 0 0 rgba(0, 0, 0, 0)",
            textColor: "#fff",
            zIndex: 1000,
          },
          buttonBack: {
            backgroundColor: '#669523',
            color: 'white',
          },
          
          buttonNext: {
            backgroundColor: '#669523',
            color: 'white',
          },
          
        }}
      />
      <ReactBreadcrumb
        items={[
          {
            href: "/dashboard/programs",
            name: "Mes programmes",
          },
          {
            href: ``,
            name: program?.name || "...",
          },
        ]}
      />
      <h1 className="mb-2 mt-4 text-[24px] font-medium inline-block" id="program-title">Programme {program?.name}</h1>
      <p className="text-[#626262] mb-6">
        {program?.description}
      </p>
      
      <div className="flex flex-wrap" >
        <div className="basis-1/2 pr-4 min-h-40 relative" >
          <div className="absolute right-0 top-0 w-1 h-40 " id="projects-lists"></div>
          {programs.loading && <LoadingSpinner />}
          {program && program?.projects?.length > 0 ? (
            <ProjectList projects={program?.projects} programId={program?.id} />
          ) : (
            !programs.loading && (
              <div>
                Il n'y a pas encore de projets dans votre programme. Veuillez en
                ajouter un en cliquant sur le bouton "Ajouter".
              </div>
            )
          )}
          {/* On affiche le bouton "ajouter" qui est en dehors du composant ProjectList quand il n'y a pas de projet afin d'en ajouter un */}
          {program?.projects?.length == 0 && (
            <div className="mb-4 mt-5 h-full min-w-52  sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%]">
              <Button
                variant="outline"
                className=" flex size-full h-[170px] items-center justify-center rounded-[15px] border"
                style={{ background: "#EFEFEF" }}
                onClick={() => setOpenModal({ name: "project", value: true })}
              >
                <div className="flex w-max  items-center">
                  <svg
                    width="48"
                    height="36"
                    viewBox="0 0 48 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                      fill="#D4D4D4"
                    />
                    <rect
                      x="25"
                      y="19"
                      width="3"
                      height="11"
                      rx="1"
                      fill="#D9D9D9"
                    />
                    <rect
                      x="32"
                      y="23"
                      width="3"
                      height="11"
                      rx="1"
                      transform="rotate(90 32 23)"
                      fill="#D9D9D9"
                    />
                  </svg>
                </div>
              </Button>
              <div className=" mt-4 flex cursor-pointer items-center gap-2 font-semibold text-foreground opacity-70">
                Ajouter
              </div>
            </div>
          )}

          {!programs.loading && <AddProject onHandleSubmit={handleSubmit} />}
        </div>
        {program?.projects?.length > 0 && 
        <div className="basis-1/2 pl-4">
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Modifier la sélection</SheetTitle>
                <SheetDescription>
                  Choisissez les projets que vous voulez additionner
                </SheetDescription>
              </SheetHeader>
              <div className="flex items-center space-x-2 border-b pb-2 mt-5">
                <Checkbox
                  checked={projectSelected.length === program?.projects.length}
                  onCheckedChange={() => handleChangeProjectSelected({name: "all", id: 0})}
                  id="all"
                />
                <label
                
                  htmlFor="all"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                >
                  {projectSelected.length === program?.projects.length ? 'Désélectionner tout' : 'Sélectionner tout'}
                </label>
              </div>
              <div className="mt-2 flex flex-col space-y-3 overflow-y-auto" style={{maxHeight: "calc(100vh - 154px)"}}>
                {program?.projects?.map((project:{name:string;id:number}) => (
                  <div key={project.id} className="flex items-center space-x-2">
                    <Checkbox
                      id={`terms-${project.id}`}
                      checked={isChecked({name: project.name, id: project.id})}
                      onCheckedChange={() => handleChangeProjectSelected({name:project.name,id:project.id})}
                    />
                    <label
                      htmlFor={`terms-${project.id}`}
                      className="text-sm  leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                    >
                      {project.name}
                    </label>
                  </div>
                ))}
              </div>
            </SheetContent>
          </Sheet>
          <Tabs
            className=" w-full"
            defaultValue="objectif"
          >
            <TabsList className="mb-2 grid w-full grid-cols-2 bg-background">
              <TabsTrigger
                value="objectif"
                className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
              >
                Objectifs du programme
              </TabsTrigger>
              <TabsTrigger
                value="zone"
                className="rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none"
              >
                Zone du programme
              </TabsTrigger>
            </TabsList>
            <TabsContent value="objectif" className="mb-4">
              <>
                <div className="mt-4 flex justify-end">
                  <Button variant={"outline"} onClick={() => setIsSheetOpen(true)} className="edit-selection-btn">
                    <Edit className="mr-3 size-5"/>
                    Modifier la sélection
                  </Button>
                </div>
                {dataReporting?.projectTotalIndicators?.length > 0 && <Suspense fallback={<LoadingSpinner />}>
                  <ErrorBoundary>
                    <div className="mt-4 rounded-[20px] bg-[#F7F9FB] p-[20px]" id="project-cards">
                      {dataReporting?.projectTotalIndicators.filter(ind => ind.type === 1 && ind.indicator.programReporting !== null).length === 0 && <p className="m-0 text-left">Aucune donnée à afficher.</p>}
                      {dataReporting?.projectTotalIndicators.filter(ind => ind.type === 1 && ind.indicator.programReporting !== null).length > 0 && <StatCardColoredDashboard indicators={dataReporting?.projectTotalIndicators.filter(ind => ind.indicator.programReporting !== null)} />}
                    </div>
                  </ErrorBoundary>
                </Suspense>}
                
                <div className="flex flex-wrap mt-5" id="objectives-chart">
                  {(!data || !dataReporting) && <LoadingSpinner />}
                  {data && dataReporting && data?.indicators.filter(i => i.programReporting !== null && i.id != 2 && dataReporting.hasData.includes(i.id)).map(
                    (indicator: Indicator, k: number) =>
                      indicator.id != 2 && (
                        <ChartItemGlobal
                          key={k}
                          dataReporting={dataReporting.projectIndicators}
                          minDate={parseInt(data.minDate)}
                          maxDate={parseInt(data.maxDate)}
                          indicator={indicator}
                          currentYear={
                            // yearParam
                            //   ? Number(yearParam)
                            //   : new Date(project?.launchDate).getFullYear()
                            data.minDate
                          }
                          width="w-full"
                          maxTco2Year={dataReporting?.maxTco2Year}
                        />
                      )
                  )}
                </div>
              </>
            </TabsContent>
            <TabsContent value="zone" className="mb-4">
              <Suspense fallback={<LoadingSpinner />}>
                <div className="mt-5">
                  <div className="my-4 flex justify-end">
                    <Button variant={"outline"} onClick={() => setIsSheetOpen(true)} className="edit-selection-btn">
                      <Edit className="mr-3 size-5"/>
                      Modifier la sélection
                    </Button>
                  </div>
                  <div className="overflow-hidden rounded-[20px]">
                    <MapClusterPrograms data={[programWithProjectFiltered]} />
                  </div>
                </div>
              </Suspense>
            </TabsContent>
          </Tabs>
        </div>}
      </div>
    </div>
  );
};



export default DashboardProgram;
