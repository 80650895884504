import { z } from "zod";

import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { Eye, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { resetPassword } from "../services/authService";
import PasswordStrengthCheck from "./PasswordStrengthCheck";

const formSchema = z.object({
  password: z
    .string()
    .min(8, "Le mot de passe doit comporter au moins 8 caractères.")
    .refine(
      (password) => /[a-z]/.test(password),
      "Le mot de passe doit contenir au moins une lettre minuscule."
    )
    .refine(
      (password) => /[A-Z]/.test(password),
      "Le mot de passe doit contenir au moins une lettre majuscule."
    )
    .refine(
      (password) => /\d/.test(password),
      "Le mot de passe doit contenir au moins un chiffre."
    )
    .refine(
      (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
      "Le mot de passe doit contenir au moins un caractère spécial."
    ),
});

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mail = searchParams.get("mail");
  const code = searchParams.get("code");
  const { signIn, loading } = useAuth();
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });
  const password = form.watch("password");
  async function onSubmit(values: z.infer<typeof formSchema>) {
    if (!code || !mail) {
      return;
    }

    setFormIsLoading(true); // Active le loader avant l'appel API
    try {
      const res = await resetPassword({
        code: code,
        email: mail,
        password: values.password,
      });

      if (res.success) {
        toast({
          title: "Mot de passe changé",
        });
        setTimeout(() => {
          navigate(`/login`);
        }, 400);
      } else {
        toast({
          title: "Une erreur est survenue",
        });
      }
    } catch (error) {
      toast({
        title: "Erreur lors du changement de mot de passe",
        description: error.message || "Une erreur inattendue est survenue",
      });
    } finally {
      setFormIsLoading(false); // Désactive le loader après que l'API a répondu
    }
  }

  useEffect(() => {
    // Vérifie si toutes les règles sont respectées
    const isValid =
      password?.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setIsPasswordValid(isValid);
  }, [password]);
  return (
    <div className="mx-auto w-2/5">
      <Card>
        <CardHeader>
          <CardTitle>Choisissez un nouveau mot de passe</CardTitle>
          <CardDescription>
            Créez un mot de passe d'au moins 8 caractères. Un mot de passe fort
            doit être une combinaison de lettres, de chiffres et de symboles, et
            ne doit pas contenir votre nom ni votre adresse e-mail.
          </CardDescription>
        </CardHeader>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Mot de passe</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input
                            placeholder="Mot de passe"
                            {...field}
                            type={showPassword ? "text" : "password"}
                          />
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPassword(!showPassword);
                            }}
                            className="absolute right-2 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center"
                          >
                            {!showPassword ? <EyeOff /> : <Eye />}
                          </button>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <PasswordStrengthCheck
                  password={password}
                  isPasswordValid={isPasswordValid}
                />

                <div className="flex justify-center gap-4">
                  <Link
                    to="/submit/"
                    className={buttonVariants({ variant: "secondary" })}
                  >
                    Ignorer
                  </Link>
                  <Button
                    type="submit"
                    variant={"default"}
                    className="w-full"
                    disabled={!isPasswordValid}
                  >
                    {formIsLoading ? <LoadingSpinner /> : "Continuer"}
                  </Button>
                </div>
              </form>
            </Form>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default ResetPasswordForm;
