import { cn } from "@/libs/utils";
import { ProgramType } from "@/types/dashboard";
import { FC, useState } from "react";

import "@/components/custom/CustomFileUploader.css";
// import { useDatabase } from "@/programs/hooks/useDatabase";

const ProgramCarousel: FC<{ program: ProgramType; className?: string }> = ({
  program,
  className,
}) => {
  const [loadingCover, setLoadingCover] = useState(false);

  const [favorite, setFavorite] = useState(false);
  const [programStated, setProgramStated] = useState(program);

  const saveCover = async (media) => {
    await updateProgramInDB({ cover: media.id }, programStated.id);

    setProgramStated({
      ...programStated,
      cover: media,
    });

    setLoadingCover(false);
  };

  return (
    <>
      <div className={cn("relative", className)} title={programStated.name}>
        <a href={`/dashboard/program/${programStated.id}`}>
          <div className="group relative flex aspect-[255/200]  overflow-hidden rounded-[15px] bg-foreground">
            {loadingCover && (
              <div
                className={`relative flex size-full cursor-pointer items-center justify-center overflow-hidden border border-input  bg-[#f4f4f4]`}
              >
                <div className="customfileuploaderloader">
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </div>
            )}
            {!loadingCover && !programStated.cover && (
              <img
                src="/image-en-attente.jpg"
                alt="Card"
                className="m-auto size-full object-cover opacity-80 transition-all group-hover:scale-110 group-hover:opacity-100"
              />
            )}
            {!loadingCover && programStated.cover && (
              <img
                src={`${
                  import.meta.env.VITE_MEDIA_BASE + programStated.cover.uri
                }`}
                alt="Card"
                className="m-auto size-full object-cover opacity-80 transition-all group-hover:scale-110 group-hover:opacity-100"
              />
            )}
          </div>
        </a>
        {/* <svg
          onClick={() => setFavorite(!favorite)}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 24 24"
          fill={favorite ? "#ffffff" : "none"}
          stroke="#ffffff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="absolute right-3 top-3 md:size-3 lg:size-6"
        >
          <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
        </svg> */}
        <div className="pointer-events-none absolute inset-x-4 bottom-0 flex flex-wrap items-start px-5 py-2"></div>
        <h2 className="mt-3 line-clamp-2 text-[15px] font-semibold leading-5 text-[#2D3720]  md:text-[12px]">
          {programStated.name}
        </h2>
      </div>
    </>
  );
};
export default ProgramCarousel;
