import { useState } from "react";

// Utilitaire pour compresser l'image tout en préservant le ratio d'aspect
const compressImage = (imgToCompress, maxWidth, maxHeight, quality) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const originalWidth = imgToCompress.width;
    const originalHeight = imgToCompress.height;

    // Calculer le ratio d'aspect de l'image originale
    const aspectRatio = originalWidth / originalHeight;

    let newWidth = maxWidth;
    let newHeight = maxHeight;

    // Adapter les dimensions pour préserver le ratio d'aspect
    if (originalWidth > originalHeight) {
      newHeight = newWidth / aspectRatio; // Ajuster la hauteur selon la largeur
    } else {
      newWidth = newHeight * aspectRatio; // Ajuster la largeur selon la hauteur
    }

    // Fixer la taille du canvas aux nouvelles dimensions tout en conservant le ratio
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Dessiner l'image redimensionnée sur le canvas
    context.drawImage(imgToCompress, 0, 0, newWidth, newHeight);

    // Convertir le canvas en Blob avec le taux de qualité spécifié
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        }
      },
      "image/jpeg",
      quality
    );
  });
};

// Utilitaire pour convertir un fichier en Data URI
const fileToDataUri = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });
};

// Hook pour compresser une image
const useImageCompressor = () => {
  const [compressedImageBlob, setCompressedImageBlob] = useState(null);

  const handleCompressImage = async (
    file,
    maxWidth = 800, // Largeur maximale par défaut en pixels
    maxHeight = 600, // Hauteur maximale par défaut en pixels
    quality = 0.8 // Qualité par défaut
  ) => {
    // Vérifier si la taille du fichier est supérieure à 300 Ko (300000 octets)
    if (file.size <= 300000) {
      setCompressedImageBlob(null); // Vous pouvez aussi ne rien faire ici
      return null; // Sortir de la fonction
    }

    // Convertir le fichier en Data URI pour l'image
    const dataUri = await fileToDataUri(file);
    const imgElement = new Image();
    imgElement.src = dataUri;

    // Utilisez une promesse pour gérer l'asynchronisme
    return new Promise((resolve) => {
      imgElement.onload = async () => {
        const blob = await compressImage(
          imgElement,
          maxWidth,
          maxHeight,
          quality
        );
        setCompressedImageBlob(blob); // Mettre à jour l'état
        resolve(blob); // Résoudre la promesse avec le Blob compressé
      };

      imgElement.onerror = (error) => {
        console.error("Erreur lors du chargement de l'image :", error);
        resolve(null); // Résoudre avec null en cas d'erreur
      };
    });
  };

  return {
    compressedImageBlob,
    handleCompressImage,
  };
};

export default useImageCompressor;
