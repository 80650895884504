import * as turf from "@turf/turf";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef, useState } from "react";
import "./MapPrograms.css";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";

const MapClusterPrograms = ({ data }) => {
  // const { data } = useFetchDataV2(
  //   `${import.meta.env.VITE_API_ENDPOINT}/programs`,
  //   'programs',
  //   1000 * 60 * 60
  // );
  const mapContainerRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [activeMarkers, setActiveMarkers] = useState([]);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new maplibregl.Map({
        container: mapContainerRef.current,
        style:
          "https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=" +
          import.meta.env.VITE_API_KEY_STADIAMAP,
        center: [0, 0],
        zoom: 2,
      });

      map.addControl(new maplibregl.NavigationControl());
      setMapInstance(map);

      const markerCollection = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((program) => {
        program.projects.forEach((project) => {
          const allCoordinates = [];

          // Récupérer les locations et parcels
          if (project.location) {
            project.location.forEach((coordinates) => {
              coordinates.forEach((coord) => {
                // Inversion lat/lng -> [lng, lat]
                allCoordinates.push([coord[1], coord[0]]);
              });
            });
          }
          if (project.parcel) {
            project.parcel.forEach((parcel) => {
              allCoordinates.push(...parcel.coordinates);
            });
          }

          // Clusteriser les coordonnées par proximité
          if (allCoordinates.length > 0) {
            const points = turf.featureCollection(
              allCoordinates.map((coord) =>
                turf.point(coord, { projectName: project.name })
              )
            );

            // Regrouper en clusters (seuil : 100 km)
            const clusters = [];
            points.features.forEach((point) => {
              let added = false;
              for (const cluster of clusters) {
                const dist = turf.distance(
                  turf.center(turf.featureCollection(cluster)),
                  point,
                  { units: "kilometers" }
                );
                if (dist <= 100) {
                  cluster.push(point);
                  added = true;
                  break;
                }
              }
              if (!added) {
                clusters.push([point]);
              }
            });

            // Ajouter chaque cluster comme un centre distinct
            clusters.forEach((cluster) => {
              const clusterCenter = turf.center(
                turf.featureCollection(cluster)
              );
              markerCollection.features.push({
                type: "Feature",
                geometry: clusterCenter.geometry,
                properties: {
                  title: `programme : ${program.name} <br>project: ${project.name}`,
                  coordinates: cluster.map((c) => c.geometry.coordinates),
                  project: project,
                },
              });
            });
          }
        });
      });

      // Ajouter les marqueurs principaux
      markerCollection.features.forEach((feature) => {
        const el = document.createElement("div");
        el.className = "marker";
        el.style.width = "20px";
        el.style.height = "20px";
        el.style.backgroundColor = "red";
        el.style.borderRadius = "50%";

        const marker = new maplibregl.Marker(el)
          .setLngLat(feature.geometry.coordinates)
          .setPopup(
            new maplibregl.Popup({ offset: 25 }).setHTML(
              `<div>${feature.properties.title}</div>`
            )
          )
          .addTo(map);

        marker.getElement().addEventListener("click", () => {
          displayClusterPolygons(feature, map);
          // Supprime le marker après le clic
        });
      });

      // Lors du chargement de la carte, on ajuste certains styles ET on calcule
      // les limites pour englober tous les polygones afin de zoomer dynamiquement
      map.on("load", () => {
        map.setPaintProperty("water", "fill-color", "#75CFF0");
        map.setPaintProperty("background", "background-color", "#E7EBCC");
        map.setPaintProperty("building", "fill-color", "#E7EBCC");

        // CALCUL DES LIMITES DES POLYGONES POUR UN ZOOM INITIAL
        const polygonBounds = new maplibregl.LngLatBounds();

        // Parcourir toutes les données pour récupérer les coordonnées des polygones
        data.forEach((program) => {
          if (program.projects) {
            program.projects.forEach((project) => {
              if (project.location && Array.isArray(project.location)) {
                project.location.forEach((coords) => {
                  if (Array.isArray(coords)) {
                    coords.forEach((coord) => {
                      if (Array.isArray(coord) && coord.length >= 2) {
                        // On inverse [lat, lng] en [lng, lat]
                        polygonBounds.extend([coord[1], coord[0]]);
                      }
                    });
                  }
                });
              }
            });
          }
        });

        // Si des polygones ont été trouvés, ajuster la vue pour les englober
        if (!polygonBounds.isEmpty()) {
          map.fitBounds(polygonBounds, {
            padding: 50,
            duration: 1000, // Animation douce
          });
        }
      });

      return () => {
        map.remove();
        setMapInstance(null);
      };
    }
  }, [data]);

  const displayClusterPolygons = (feature, map) => {
    // Supprimer l'ancien polygone s'il existe
    if (map.getSource("polygon-source")) {
      map.removeLayer("polygon-layer");
      map.removeSource("polygon-source");
    }

    if (
      !feature.properties.project.location ||
      feature.properties.project.location.length === 0
    ) {
      console.error("Aucune coordonnée de polygone trouvée !");
      return;
    }

    // Construire les polygones avec des boucles fermées
    const geojsonFeatures = feature.properties.project.location
      .map((coords) => {
        if (!Array.isArray(coords) || coords.length === 0) return null; // Vérification des données

        let polygonCoords = coords.map((coord) => {
          if (coord.length === 2) {
            return [coord[1], coord[0]]; // Inversion lat/lng -> [lng, lat]
          }
          return coord;
        });
        if (
          polygonCoords[0].toString() !==
          polygonCoords[polygonCoords.length - 1].toString()
        ) {
          polygonCoords.push([...polygonCoords[0]]); // Fermer le polygone
        }

        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [polygonCoords], // Chaque ensemble de coordonnées forme un polygone fermé
          },
        };
      })
      .filter(Boolean); // Filtrer les valeurs nulles

    if (geojsonFeatures.length === 0) {
      console.error("Aucune donnée valide pour le polygone !");
      return;
    }

    // Ajouter la source GeoJSON
    map.addSource("polygon-source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: geojsonFeatures,
      },
    });

    // Ajouter un calque pour afficher les polygones
    map.addLayer({
      id: "polygon-layer",
      type: "fill",
      source: "polygon-source",
      paint: {
        "fill-color": "rgba(0, 0, 255, 0.5)", // Couleur bleue avec transparence
        "fill-outline-color": "rgba(0, 0, 255, 0.5)", // Bordure bleue
      },
    });

    // Construire le polygone en fermant la boucle (le dernier point doit être le même que le premier)
    const polygonCoordinates = [...feature.properties.coordinates];
    if (
      polygonCoordinates[0].toString() !==
      polygonCoordinates[polygonCoordinates.length - 1].toString()
    ) {
      polygonCoordinates.push(polygonCoordinates[0]);
    }
    // Définir les limites pour bien cadrer le polygone
    const bounds = new maplibregl.LngLatBounds();
    polygonCoordinates.forEach((coord) => bounds.extend(coord));

    // Vérifier que les limites ne sont pas vides avant d'ajuster la vue
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, {
        padding: 50,
        duration: 1000, // Animation douce
      });
    }
  };

  const displayClusterPolygon = (feature, map) => {
    // Vérifier si des données valides existent
    if (
      !feature.properties.coordinates ||
      feature.properties.coordinates.length === 0
    ) {
      console.error("Aucune coordonnée trouvée pour le polygone !");
      return;
    }

    // Supprimer l'ancien polygone s'il existe
    if (map.getSource("polygon-source")) {
      map.removeLayer("polygon-layer");
      map.removeSource("polygon-source");
    }

    // Construire le polygone en fermant la boucle (le dernier point doit être le même que le premier)
    const polygonCoordinates = [...feature.properties.coordinates];
    if (
      polygonCoordinates[0].toString() !==
      polygonCoordinates[polygonCoordinates.length - 1].toString()
    ) {
      polygonCoordinates.push(polygonCoordinates[0]);
    }

    // Ajouter la source GeoJSON du polygone
    map.addSource("polygon-source", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygonCoordinates], // Forme correcte pour un polygone
        },
      },
    });

    // Ajouter un calque pour afficher le polygone
    map.addLayer({
      id: "polygon-layer",
      type: "fill",
      source: "polygon-source",
      paint: {
        "fill-color": "rgba(0, 0, 255, 0.5)", // Bleu semi-transparent
        "fill-outline-color": "blue", // Bordure bleue
      },
    });

    // Définir les limites pour bien cadrer le polygone
    const bounds = new maplibregl.LngLatBounds();
    polygonCoordinates.forEach((coord) => bounds.extend(coord));

    // Vérifier que les limites ne sont pas vides avant d'ajuster la vue
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, {
        padding: 50,
        duration: 1000, // Animation douce
      });
    }
  };

  return (
    <div
      ref={mapContainerRef}
      style={{ height: "75vh", width: "100%", zIndex: "1" }}
    />
  );
};

export default MapClusterPrograms;
