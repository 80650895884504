import { FC } from 'react'
import { cn } from "@/libs/utils";

const StatCardColoredDashboard = ({indicators}) => {
    // Définir les couleurs
    const colorClasses = [
        "text-[#FF9500] bg-[#FF9500]/10",
        "text-[#59ADC4] bg-[#59ADC4]/10",
        "text-[#626262] bg-[#626262]/10",
        "text-[#FF0000] bg-[#FF0000]/10",
        "text-[#14AE5C] bg-[#14AE5C]/10"
    ];
    return (
        <div className="flex flex-wrap gap-y-4">
            {indicators?.filter(ind => ind.type === 1).map((ind, index) => {
                const colorClass = colorClasses[index % colorClasses.length]; // Répéter les couleurs cycliquement
                return (
                    <div key={ind.indicator.id} className="basis-1/3 px-2">
                        <CardColored
                            className={colorClass}
                            title={`${ind.indicator.name}`}
                            value={indicators.find(i => i.indicator.id === ind.indicator.id && i.type === 2) ? Math.round(indicators.find(i => i.indicator.id === ind.indicator.id && i.type === 2)?.total).toLocaleString("fr-FR") : 0 || 0}
                            desc={`Obj ${ind.totalYear || 1} ans : ${Math.round(ind.total).toLocaleString("fr-FR") || 0}`}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const CardColored: FC<{ title: string; value: string; desc: string; className: string }> = ({ title, value, desc, className }) => {
    return (
        <div className={cn('rounded-[11px] px-[10px] py-[13px]', className)} title={title}>
            <h3 className="mb-2 text-[14px] font-semibold line-clamp-1">
                {title}
            </h3>
            <p className="mb-1 text-[20px] font-semibold">{value.toString().slice(0, 15)}</p>
            <p className="mb-0 text-[12px]">{desc}</p>
        </div>
    )
}
export default StatCardColoredDashboard;
