import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Chart from "@/dashboard/components/project/components/Chart";
import { usePreviewPerformance } from "@/dashboard/store/store";
import { cn } from "@/libs/utils";
import { Indicator } from "@/types";
import { ChevronDown, ListCollapse, Maximize2, Minimize2, Settings } from "lucide-react";
import { FC, useState } from "react";
import ChartGlobal from "./ChartGlobal";

const ChartItemGlobal: FC<{
  dataReporting: any;
  minDate: any;
  maxDate: any;
  indicator: Indicator;
  className?: string;
  currentYear: number;
  width: string;
  maxTco2Year: number;
}> = ({ dataReporting, minDate, maxDate, indicator, className, currentYear, width = "basis-1/2", maxTco2Year }) => {
  const [size, setSize] = useState(width);
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { previews, setPreviews } = usePreviewPerformance();
  return (
    <div className={cn(`mb-4 ${size}`, className)}>
      <div className="group relative rounded-[20px] bg-[#F7F9FB] p-4 px-0">
        <h3 className={`${isCollapsed ? 'mb-4' : ''} ml-8 font-semibold text-[#626262] flex flex-wrap items-center gap-2 cursor-pointer`} onClick={() => setIsCollapsed(!isCollapsed)}>
          <ChevronDown size={17} />
          {indicator.name}
        </h3>
        {isCollapsed && 
          <>
            <ChartGlobal
            dataReporting={dataReporting}
            minDate={minDate}
            maxDate={maxDate}
            indicator={indicator}
            currentYear={currentYear}
            maxTco2Year={maxTco2Year}
           />
            <Indice />
            <button
              className=" absolute right-4 top-4 "
              onClick={() =>
                setSize((prev) => (prev == "w-1/2" ? "w-full" : "w-1/2"))
              }
            >
              {size == "w-1/2" ? (
                <Maximize2 size={15} className="text-gray-700" />
              ) : (
                <Minimize2 size={15} className="text-gray-700" />
              )}
            </button>
          </>
        }
      </div>
    </div>
  );
};

const Indice = () => {
  return (
    <div className="ml-10 mt-5 flex items-center gap-3">
      <div className="flex items-center gap-1">
        <div className={`size-2 rounded-full bg-[#59ADC4]`}></div>
        Prévu
      </div>
      <div className="flex items-center gap-1">
        <div className={`size-2 rounded-full bg-[#A7FFD0]`}></div>
        Réalisé
      </div>
      <div className="flex items-center gap-1">
        <div className={`size-2 rounded-full bg-[#CFE7FF]`}></div>
        Verifié / justifié / certifié
      </div>
    </div>
  );
};
export default ChartItemGlobal;
