import { useAuth } from "@/auth/hooks/useAuth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, buttonVariants } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { useModal } from "@/dashboard/store/store";
import usePostData from "@/hooks/usePostData";
import { LogOut, User2, UserPen } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HeaderTop = () => {
  const { user, logout, setAuthState } = useAuth();
  const { setOpenModal } = useModal();
  const page = window.location.pathname;

  const [openEditProfil, setOpenEditProfil] = useState(false);

  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [structureName, setStructureName] = useState(user?.structureName);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [loadingEditProfil, setLoadingEditProfil] = useState(false);

  const { postData } = usePostData();

  const { toast } = useToast();

  useEffect(() => {
    setFirstname(user?.firstname);
    setLastname(user?.lastname);
    setStructureName(user?.structureName);
    setEmail(user?.email);
  }, [user]);

  const handleEditProfil = async () => {
    setLoadingEditProfil(true);

    const res = await postData({
      url: `${import.meta.env.VITE_IP_API}/api/profil`,
      data: {
        firstname,
        lastname,
        structureName,
        email,
        password,
        confirmPassword,
      },
    });
    if (res === null) {
      setLoadingEditProfil(false);
      return;
    }

    setAuthState({ user: res, loading: false, error: null });

    setLoadingEditProfil(false);

    toast({
      title: "Votre profil a bien été modifié.",
      duration: 1000
    });

    setOpenEditProfil(false);
  };
  
  return (
    <div className="flex flex-wrap justify-between py-4">
      {page.search("programs") >= 1 || page === '/dashboard' || page === '/dashboard/' ? (
        <button
          className={`h-auto py-2 font-normal ${buttonVariants({
            variant: "roundedGray",
          })}`}
          style={{ borderRadius: "50px" }}
          onClick={() => page === '/dashboard' || page === '/dashboard/' ? navigate('/dashboard/programs?openmodal=true') : setOpenModal({ name: "program", value: true })}
          id="add-program"
        >
          Ajouter un programme
        </button>
      ) : (
        <button
          className={`h-auto py-2 font-normal ${buttonVariants({
            variant: "roundedGray",
          })}`}
          style={{ borderRadius: "50px" }}
          onClick={() => setOpenModal({ name: "project", value: true })}
          id="add-project"
        >
          Ajouter un projet
        </button>
      )}
      <div className="flex items-center gap-5">
        <Button variant={"roundedOrange"} className="h-auto py-2 font-normal">
          Carte des projets
        </Button>
        <Popover>
          <PopoverTrigger asChild>
            <div className="flex cursor-pointer items-center gap-1 text-[#878787]">
              {user ? (
                <>
                  <User2 />
                  {user?.firstname} {user?.lastname}
                </>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-max">
            <div className="mb-2 flex items-center gap-1">
              <User2 />
              <div>
                <p className="m-0 text-xs font-light">Connecté en tant que</p>
                <p className="m-0">
                  {user?.firstname} {user?.lastname}
                </p>
              </div>
            </div>
            <hr />
            <AlertDialog open={openEditProfil} onOpenChange={setOpenEditProfil}>
              <AlertDialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2 py-2 text-[#878787] transition-all hover:text-[#555]">
                  <UserPen size={20} />
                  Profil
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Modifier mon profil</AlertDialogTitle>
                </AlertDialogHeader>
                <div className="mt-3">
                  <div className="space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Nom
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      name="name"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Prènom
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Nom de la structure
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      name="structureName"
                      value={structureName}
                      onChange={(e) => setStructureName(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Email
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Mot de passe
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      autoCorrect="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Confirmation mot de passe
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      type="password"
                      name="confirmPassword"
                      autoComplete="new-password"
                      autoCorrect="off"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annuler</AlertDialogCancel>
                  <AlertDialogAction
                    disabled={loadingEditProfil}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditProfil();
                    }}
                  >
                    Sauvegarder
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <hr />
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2 py-2 text-[#878787] transition-all hover:text-destructive">
                  <LogOut size={20} />
                  Deconnexion
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annuler</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => logout(window.location.href)}
                  >
                    Se déconnecter
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default HeaderTop;
