import { useEffect, useState } from "react";
import EditPhotoHeader from "./EditPhotoHeader";

const ProjectBreadcrumb = ({ project }) => {
  const [picture, setPicture] = useState({
    uri: "",
    alt: "",
  });
  const [uploadLogoLoading, setUploadLogoLoading] = useState(false);
  const typeProject = project.type

  useEffect(() => {
    setPicture({
      uri: project?.defaultImage?.uri || "",
      alt: project?.defaultImage?.alt || "",
    });
    setUploadLogoLoading(false);
  }, [project]);

  return <div className="mb-4 h-[150px] w-full overflow-hidden">
  <div className="group relative size-full overflow-hidden rounded-[20px] bg-[#f4f4f4]">
    <div className="absolute inset-2 z-10">
      <EditPhotoHeader
        setPicture={setPicture}
        picture={picture}
        uploadLogoLoading={uploadLogoLoading}
        setUploadLogoLoading={setUploadLogoLoading}
      />
    </div>
    {!uploadLogoLoading && picture.uri !== "" && (
      <img
        src={import.meta.env.VITE_MEDIA_BASE + picture.uri}
        alt={picture.alt}
        className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
      />
    )}
    {!uploadLogoLoading &&
      picture.uri === "" &&
      typeProject !== undefined &&
      typeProject.image && (
        <img
          src={import.meta.env.VITE_MEDIA_BASE + typeProject.image.uri}
          alt=""
          className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
        />
      )}
    {!uploadLogoLoading &&
      picture.uri === "" &&
      typeProject !== undefined &&
      !typeProject.image && (
        <img
          src="/pexels-pixabay-235925.jpg"
          alt=""
          className="size-full object-cover   transition-all hover:blur-0 group-hover:blur-0"
        />
      )}
  </div>
</div>
}

export default ProjectBreadcrumb