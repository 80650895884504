import CurrencyAPI from "@everapi/currencyapi-js";

async function saveRate(data) {
  const token = localStorage.getItem("jwtToken");

  try {
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const res = await fetch(
      `${import.meta.env.VITE_API_ENDPOINT}/exchange_rate/save`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      }
    );

    return res;
  } catch (error) {}
}

export async function checkRate(devise) {
  const devises = localStorage.getItem("devises");
  if (devises) {
    const devisesJSON = JSON.parse(devises);
    devise = devisesJSON.filter((d: any) => d.code === devise.code)[0];
  }

  if (
    devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    ).length > 0
  ) {
    const rate = devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    )[0].rate;

    return rate;
  } else {
    let response = {
      meta: {
        last_updated_at: "2024-09-18T23:59:59Z",
      },
      data: {
        EUR: {
          code: "EUR",
          value: 0.0001978083,
        },
      },
    };

    if (import.meta.env.VITE_ACTIVE_AMOUNTCONVERTER == 1) {
      const currencyApi = new CurrencyAPI(
        import.meta.env.VITE_CURRENCYAPI_API_KEY
      );

      response = await currencyApi.latest({
        base_currency: devise.code,
        currencies: "EUR",
      });
    }

    if (
      response.data !== undefined &&
      response.data.EUR !== undefined &&
      response.data.EUR.value !== undefined
    ) {
      const data = {
        initialCurrency: devise.code,
        targetCurrency: "EUR",
        rate: response.data.EUR.value,
      };

      const res = await saveRate(data);
      if (res !== null) {
        const devises = await res.json();

        localStorage.setItem("devises", JSON.stringify(devises));
      }

      return response.data.EUR.value;
    }
  }
}

export async function convertToEuro(amount: number, devise: any) {
  if(devise.code === 'EUR') return amount;

  const devises = localStorage.getItem("devises");
  if (devises) {
    const devisesJSON = JSON.parse(devises);
    devise = devisesJSON.filter((d: any) => d.code === devise.code)[0];
  }

  if (
    devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    ).length > 0
  ) {
    const rate = devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    )[0].rate;

    return amount * rate;
  } else {
    let response = {
      meta: {
        last_updated_at: "2024-09-18T23:59:59Z",
      },
      data: {
        EUR: {
          code: "EUR",
          value: 0.0001978083,
        },
      },
    };

    if (import.meta.env.VITE_ACTIVE_AMOUNTCONVERTER == 1) {
      const currencyApi = new CurrencyAPI(
        import.meta.env.VITE_CURRENCYAPI_API_KEY
      );

      response = await currencyApi.latest({
        base_currency: devise.code,
        currencies: "EUR",
      });
    }

    if (
      response.data !== undefined &&
      response.data.EUR !== undefined &&
      response.data.EUR.value !== undefined
    ) {
      amount = amount * response.data.EUR.value;
    }

    return amount;
  }
}

export async function convertEuroToCurrency(amount: number, devise: any) {
  if(devise.code === 'EUR') return amount;

  const devises = localStorage.getItem("devises");
  if (devises) {
    const devisesJSON = JSON.parse(devises);
    devise = devisesJSON.filter((d: any) => d.code === devise.code)[0];
  }

  if (
    devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    ).length > 0
  ) {
    const rate = devise.exchangeRates.filter(
      (exchangeRate) => exchangeRate.targetCurrency.code === "EUR"
    )[0].rate;

    return amount / rate;
  } else {
    let response = {
      meta: {
        last_updated_at: "2024-09-18T23:59:59Z",
      },
      data: {
        EUR: {
          code: "EUR",
          value: 0.0001978083,
        },
      },
    };

    if (import.meta.env.VITE_ACTIVE_AMOUNTCONVERTER == 1) {
      const currencyApi = new CurrencyAPI(
        import.meta.env.VITE_CURRENCYAPI_API_KEY
      );

      response = await currencyApi.latest({
        base_currency: devise.code,
        currencies: "EUR",
      });
    }

    if (
      response.data !== undefined &&
      response.data.EUR !== undefined &&
      response.data.EUR.value !== undefined
    ) {
      amount = amount / response.data.EUR.value;
    }

    return amount;
  }
}
