import { File, Folder, Tree } from "@/components/extension/tree-view-api";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { projectStatus } from "@/constants/constants";
import { useProjectStore, useTabDashboard } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types/dashboard";
import { t } from "i18next";
import { Check, ChevronRight } from "lucide-react";
import { FC } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
const TreeView = () => {
  const { project } = useProjectStore();
  const { id } = useParams<{ id: string }>();
   
  const { tab, setTab } = useTabDashboard();
  const [searchParams] = useSearchParams();
  const stepParams = searchParams.get("step");
  const currentStep = stepParams ? parseInt(stepParams) : 1;
  const navigate = useNavigate();
  const page = window.location.pathname;
  const currentPage = page.includes("programs")
    ? "programs"
    : page.includes("project")
    ? "project"
    : page.includes("program")
    ? "program"
    : "others";
  const {
    data: programs,
    loading,
    error,
  } = useFetchDataV2<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`,
    `programs`,
    1000 * 60 * 60
  );
  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: programs
        ?.map((item: ProgramType, key: number) => {
          return {
            id: `program-${item.id}`,
            isSelectable: true,
            name: item.name + "-" + item.id,
            children: item?.projects
              ?.map((project: { name: string; id: number }) => {
                return {
                  id: `project-${project.id}`,
                  isSelectable: true,
                  name: project.name + "-" + project.id,
                  children: [
                    {
                      id: `project-${project.id}`,
                      isSelectable: true,
                      name: "Descritif",
                    },
                    {
                      id: `project-${project.id}-step-1`,
                      isSelectable: true,
                      name: "Détails du projet",
                    },
                    {
                      id: `project-${project.id}-step-2`,
                      isSelectable: true,
                      name: "Objectifs du projet",
                    },
                    {
                      id: `project-${project.id}-step-3`,
                      isSelectable: true,
                      name: "Financements",
                    },
                    {
                      id: `project-${project.id}-step-4`,
                      isSelectable: true,
                      name: "Autres informations",
                    },
                    {
                      id: `project-${project.id}-step-5`,
                      isSelectable: true,
                      name: "Parcelle et localisation",
                    },
                    {
                      id: `project-${project.id}-step-6`,
                      isSelectable: true,
                      name: "Pièces jointes",
                    },
                  ],
                };
              })
              .filter(Boolean),
          };
        })
        .filter(Boolean),
    },
  ];

  // Calculer l'ID initial sélectionné
  let initialSelectedId = "1";
  initialSelectedId =
    currentPage === "program"
      ? `program-${id}`
      : currentPage === "project"
      ? `project-${id}`
      : "1";

  // Gestion du chargement et des erreurs
  if (loading) return <LoadingSpinner />;
  if (error) return <div>Une erreur est survenue: {error}</div>;

  return (
    <Tree
      initialSelectedId={initialSelectedId}
      elements={elements}
      className="mb-4 "
      id="mes-programmes"
    >
      <Folder element="Mes programmes" value="1" visibleChevron={true}>
        <div className="flex flex-col gap-1">
          {programs?.map((item: ProgramType, key: number) => (
            <Folder
              element={item.name}
              value={`program-${item.id}`}
              key={key}
              visibleChevron={true}
              projectId={item.id}
              className={`${
                item.id.toString() == id ? "bg-primary/10" : ""
              } py-1`}
            >
              {item.projects.map((p: any, k: number) => (
                <Folder
                  key={p.id}
                  value={`project-${p.id}`}
                  element={p.name}
                  className={`w-full items-start text-left `}
                  projectId={p.id}
                  visibleChevron={true}
                >
                  {/* Descriptif du projet */}
                  <File
                    id={`project-${p.id}-step-1`}
                    value={`project-${p.id}-step-1`}
                    fileIcon=""
                    className="-mt-2 w-full"
                  >
                    <StepMenuTab
                      title={t("Descriptif")}
                      link={`/dashboard/project/${p.id}?tab=descriptif`}
                      currentTab={tab}
                      tab="descriptif"
                      setTab={setTab}
                    />
                  </File>

                  <File 
                    id={`project-${p.id}-step-1-1`} value={`project-${p.id}-step-1`} fileIcon="">
                    <StepMenu
                      title={t("Détails du projet")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=1`);
                      }}
                      currentStep={currentStep}
                      step={1}
                      state={
                        p.id === project?.id ? project?.step >= 1 : p.step >= 1
                      }
                      stepLabel="1"
                    />
                  </File>
                  <File
                    id={`project-${p.id}-step-2`}
                    value={`project-${p.id}-step-2`}
                    fileIcon=""
                    className={
                      p.id === project?.id
                        ? project?.step == null || project?.step < 2
                          ? "pointer-events-none opacity-50"
                          : ""
                        : p.step == null || p.step < 2
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Objectifs du projet")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=2`);
                      }}
                      currentStep={currentStep}
                      step={2}
                      state={
                        p.id === project?.id ? project?.step >= 2 : p.step >= 2
                      }
                      stepLabel="2"
                    />
                  </File>
                  <File
                    id={`project-${p.id}-step-3`}
                    value={`project-${p.id}-step-3`}
                    fileIcon=""
                    className={
                      p.id === project?.id
                        ? project?.step == null || project?.step < 3
                          ? "pointer-events-none opacity-50"
                          : ""
                        : p.step == null || p.step < 3
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Financements")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=3`);
                      }}
                      currentStep={currentStep}
                      step={3}
                      state={
                        p.id === project?.id ? project?.step >= 3 : p.step >= 3
                      }
                      stepLabel="3"
                    />
                  </File>
                  {p.id === project?.id
                    ? project?.type.fields.find(
                        (type) =>
                          type.name === "protectedSpecies" ||
                          type.name === "plantedSpecies" ||
                          type.name === "protectedSpecies"
                      ) && (
                        <File
                          id={`project-${p.id}-step-4`}
                          value={`project-${project?.id}-step-4`}
                          fileIcon=""
                          className={
                            project?.step == null || project?.step < 4
                              ? "pointer-events-none opacity-50"
                              : ""
                          }
                        >
                          <StepMenu
                            title={t("Autres informations")}
                            onClick={() => {
                              navigate(
                                `/dashboard/project/${project?.id}?step=4`
                              );
                            }}
                            currentStep={currentStep}
                            step={4}
                            state={project?.step >= 4}
                            stepLabel="4"
                          />
                        </File>
                      )
                    : p?.type.fields.find(
                        (type) =>
                          type.name === "protectedSpecies" ||
                          type.name === "plantedSpecies" ||
                          type.name === "protectedSpecies"
                      ) && (
                        <File
                          id={`project-${p.id}-step-4`}
                          value={`project-${p.id}-step-4`}
                          fileIcon=""
                          className={
                            p.id === project?.id
                              ? project?.step == null || project?.step < 4
                                ? "pointer-events-none opacity-50"
                                : ""
                              : p.step == null || p.step < 4
                              ? "pointer-events-none opacity-50"
                              : ""
                          }
                        >
                          <StepMenu
                            title={t("Autres informations")}
                            onClick={() => {
                              navigate(`/dashboard/project/${p.id}?step=4`);
                            }}
                            currentStep={currentStep}
                            step={4}
                            state={
                              p.id === project?.id
                                ? project?.step >= 4
                                : p.step >= 4
                            }
                            stepLabel="4"
                          />
                        </File>
                      )}
                  <File
                    id={`project-${p.id}-step-5`}
                    value={`project-${p.id}-step-5`}
                    fileIcon=""
                    className={
                      p.id === project?.id
                        ? project?.step == null || project?.step < 5
                          ? "pointer-events-none opacity-50"
                          : ""
                        : p.step == null || p.step < 5
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Parcelle et localisation")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=5`);
                      }}
                      currentStep={currentStep}
                      step={5}
                      state={
                        p.id === project?.id ? project?.step >= 5 : p.step >= 5
                      }
                      stepLabel={
                        project?.type.fields.find(
                          (type) =>
                            type.name === "protectedSpecies" ||
                            type.name === "plantedSpecies" ||
                            type.name === "protectedSpecies"
                        )
                          ? "5"
                          : "4"
                      }
                    />
                  </File>
                  <File
                    id={`project-${p.id}-step-6`}
                    value={`project-${p.id}-step-6`}
                    fileIcon=""
                    className={
                      p.id === project?.id
                        ? project?.step == null || project?.step < 6
                          ? "pointer-events-none opacity-50"
                          : ""
                        : p.step == null || p.step < 6
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Pièces jointes")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=6`);
                      }}
                      currentStep={currentStep}
                      step={6}
                      state={
                        p.id === project?.id ? project?.step >= 6 : p.step >= 6
                      }
                      stepLabel={
                        project?.type.fields.find(
                          (type) =>
                            type.name === "protectedSpecies" ||
                            type.name === "plantedSpecies" ||
                            type.name === "protectedSpecies"
                        )
                          ? "6"
                          : "5"
                      }
                    />
                  </File>
                  {/* Suivi de perfo */}
                  <File
                    id={`project-${p.id}-step-7`}
                    value="suivi"
                    fileIcon=""
                    className={`${
                      p.status !== projectStatus.Validated
                        ? "pointer-events-none opacity-25"
                        : ""
                    }`}
                  >
                    <StepMenuTab
                      title={t("Suivi de performance ESG")}
                      link={`/dashboard/project/${p.id}?tab=suivi-perf`}
                      currentTab={tab}
                      tab="suivi-perf"
                      setTab={setTab}
                    />
                  </File>
                  {/* Reporting */}
                  <File
                    id={`project-${p.id}-step-8`}
                    value="reporting"
                    fileIcon=""
                    className={`${
                      p.status !== projectStatus.Validated
                        ? "pointer-events-none opacity-25"
                        : ""
                    }`}
                  >
                    <StepMenuTab
                      title={t("Reporting")}
                      link={`/dashboard/project/${p.id}?tab=reporting`}
                      currentTab={tab}
                      tab="reporting"
                      setTab={setTab}
                    />
                  </File>
                </Folder>
              ))}
            </Folder>
          ))}
        </div>
      </Folder>
    </Tree>
  );
};

const StepMenu: FC<
  {
    title: string;
    currentStep: number;
    step: number;
    state: boolean;
    stepLabel?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, currentStep, step, state, stepLabel, ...props }) => {
  return (
    <div className={`relative ml-5`} {...props}>
      <div className={`flex items-start rounded-md py-1 pl-0 text-left`}>
        <p className="mb-0 flex items-center text-[13px]">
          {state ? (
            <span
              className={`mr-2 flex size-6 items-center justify-center ${
                currentStep == step
                  ? "bg-primary text-background"
                  : "bg-primary/15"
              } rounded-full`}
            >
              <Check size={15} />{" "}
            </span>
          ) : (
            stepLabel && (
              <span
                className={`mr-2 flex size-6 items-center justify-center text-[13px] ${
                  currentStep == step
                    ? "bg-primary text-background"
                    : "bg-primary/15 text-foreground"
                }  rounded-full`}
              >
                {stepLabel}
              </span>
            )
          )}
          <span
            className={`flex-1 text-left text-[13px] ${
              currentStep >= step ? "font-semibold" : ""
            } ${
              !stepLabel
                ? "rounded-sm bg-primary/15 px-2 py-1 font-semibold"
                : ""
            } line-clamp-2`}
          >
            {title}
          </span>
        </p>
      </div>
    </div>
  );
};

const StepMenuTab: FC<
  {
    title: string;
    link: string;
    currentTab: string;
    tab: "descriptif" | "reporting" | "suivi-perf";
    setTab: (tab: "descriptif" | "reporting" | "suivi-perf") => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, link, currentTab, tab, setTab, ...props }) => {
  return (
    <div className={`relative ml-3 w-full`} {...props}>
      <Link
        to={link}
        className={`flex w-full items-start rounded-md py-1 pl-0 text-left relative`}
        onClick={() => setTab(tab)}
      >
        {currentTab == tab && <ChevronRight className="absolute right-full size-5 text-[#878787] top-1/2 -translate-y-1/2"/>}
        <p className="mb-0 flex w-full items-center text-[13px]">
          <span
            className={`line-clamp-2 w-full rounded-sm ${
              currentTab == tab ? "bg-primary/15 font-semibold text-[#494949]" : "text-[#878787]"
            } px-2 py-1 text-left text-[13px] `}
          >
            {title}
          </span>
        </p>
      </Link>
    </div>
  );
};

export default TreeView;
