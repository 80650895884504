import { useAuth } from "@/auth/hooks/useAuth";
import ErrorBoundary from "@/components/errors/ErrorBoundary";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { cn } from "@/libs/utils";
import {
  Award,
  Bell,
  FolderIcon,
  LayoutDashboard,
  MessagesSquare,
  Power,
  ScrollText,
  Settings,
} from "lucide-react";
import { FC, ReactNode, Suspense, useState } from "react";
import { Link } from "react-router-dom";
import TreeView from "./TreeView";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { Badge } from "@/components/ui/badge";
import usePostData from "@/hooks/usePostData";

type LeftbarProps = {
  handleToggleLeftbar: (val: boolean) => void;
  isOpen: boolean;
};
const Leftbar: FC<LeftbarProps> = ({ handleToggleLeftbar, isOpen }) => {
  const { isAuthenticated, logout, loading, user } = useAuth();
  const notifications = useFetchDataV2(
    `${import.meta.env.VITE_IP_API}/notifications/indicator/${user?.id}`,
    `indicator-${user?.id}`
  );
  const [openNotifications, setOpenNotifications] = useState(false);
  const { postData } = usePostData<any>();
  const [loadingReadNotification, setLoadingReadNotification] = useState(false);

  return (
    <div
      className={`sticky top-0 z-50 flex h-screen min-h-screen flex-col overflow-y-auto py-8 ${
        isOpen ? "pl-4 pr-2" : "flex flex-col items-center px-2"
      }  bg-[#f4f4f4] bg-cover bg-center`}
    >
      
      <Link to="/dashboard">
        {!isOpen ? (
          <img
            src="/logo-min.svg"
            alt="logo verdeo"
            width={20}
            className="mb-7"
          />
        ) : (
          <img src="/logo.svg" alt="logo verdeo" width={90} className="mb-7" />
        )}
      </Link>

      <ul>
        <LinkNav isOpen={isOpen} picto={<LayoutDashboard size={18} />} link="/">
          Dashboard
        </LinkNav>
      </ul>

      {isOpen ? (
        <Suspense fallback={<LoadingSpinner />}>
          <ErrorBoundary>
            <TreeView />
          </ErrorBoundary>
        </Suspense>
      ) : (
        <button className="mb-4" onClick={() => handleToggleLeftbar(true)}>
          <FolderIcon className="flex size-5 opacity-70" />{" "}
        </button>
      )}
      <ul>
        <LinkNav
          isOpen={isOpen}
          picto={<ScrollText size={18} />}
          link="/"
          title="Bientôt disponible"
          className="pointer-events-none opacity-45"
        >
          Mes factures
        </LinkNav>
        <LinkNav
          isOpen={isOpen}
          picto={<Award size={18} />}
          link="/"
          title="Bientôt disponible"
          className="pointer-events-none opacity-45"
        >
          Mes certificats
        </LinkNav>
        {/* <LinkNav isOpen={isOpen} picto={<User2 size={18} />} link="/">
          Profil
        </LinkNav> */}
        <AlertDialog
          open={openNotifications}
          onOpenChange={setOpenNotifications}
        >
          <AlertDialogTrigger asChild>
            <li className="mb-4" title={""}>
              <Link
                onClick={e => {
                  e.preventDefault();
                  setOpenNotifications(true);
                }}
                to={`/dashboard`}
                className={`line-clamp-1 flex flex-wrap items-center gap-2 text-left text-[13px] leading-4 text-[#878787]  hover:text-[#1C5114] ${
                  !isOpen ? "mb-3  justify-center" : "mb-3"
                }`}
              >
                <Bell size={18} /> {isOpen && <>Notifications{!notifications.loading && <Badge>{notifications.data?.filter(n => n.status === 0).length}</Badge>}</>}
              </Link>
            </li>
          </AlertDialogTrigger>
          <AlertDialogContent>
          {loadingReadNotification && <div className="fixed z-[9999999999] flex items-center justify-center top-0 left-0 w-full h-full bg-[#00000080]">
            <div className="flex gap-2 text-[#fff]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                fill="none"
                color="#fff"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="animate-spin"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
              Chargement...  
            </div>  
          </div>}
            <AlertDialogHeader>
              <AlertDialogTitle>
              Notifications&nbsp;&nbsp;{!notifications.loading && <Badge>{notifications.data?.filter(n => n.status === 0).length}</Badge>}
              </AlertDialogTitle>
            </AlertDialogHeader>
            <div className="mt-3 max-h-[50vh] overflow-y-auto">
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 cursor-pointer">
                  {notifications.data?.map((notification) => <div className={`flex flex-col py-3 px-3 ${notification.status === 0 ? 'bg-slate-100' : ''}`} onClick={async () => {
                    const ids = notification.id.split('_');
                    const idProject = ids[0];
                    setLoadingReadNotification(true);
                    await postData({
                      url: `${import.meta.env.VITE_IP_API}/notification/read/${
                        notification.id
                      }`,
                      data: {},
                    });
                    window.location.href = `/dashboard/project/${idProject}?tab=suivi-perf`
                  }}>
                    <dt className="mb-1 text-gray-500 text-sm flex gap-4" title={notification.message}>
                      <div className="size-[100px] rounded-sm overflow-hidden">
                        <img className="size-full object-cover" src={import.meta.env.VITE_MEDIA_BASE + notification.thumb} alt={notification.message} />
                      </div>
                      <div style={{
                        width: "calc(100% - 100px)"
                      }}
                        >{notification.message}</div>
                    </dt>
                </div>)}
              </dl>
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel>Fermer</AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <LinkNav
          isOpen={isOpen}
          picto={<MessagesSquare size={18} />}
          link="/"
          title="Bientôt disponible"
          className="pointer-events-none opacity-45"
        >
          Messages
        </LinkNav>
      </ul>
      <ul className="mt-auto">
        <LinkNav
          isOpen={isOpen}
          picto={<Settings size={20} />}
          link="/"
          className="pointer-events-none mb-0 opacity-45"
        >
          Paramètres
        </LinkNav>
        <div
          className={`mt-auto flex flex-wrap ${
            isOpen ? "justify-between" : "justify-center"
          } items-center transition-all`}
        >
          {!isAuthenticated && !loading ? (
            <Link
              to="/login"
              className="rounded-[4rem] border-2 border-foreground bg-background px-4 py-2 text-[#878787] transition-all hover:bg-foreground hover:text-background"
            >
              Connexion
            </Link>
          ) : (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2 text-[#878787] transition-all hover:text-destructive">
                  <Power size={18} className="text-[#878787]" />
                  {isOpen && "Deconnexion"}
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annuler</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => logout(window.location.href)}
                  >
                    Se déconnecter
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </ul>
    </div>
  );
};

const LinkNav = ({
  isOpen,
  link,
  title,
  picto,
  children,
  className,
}: {
  isOpen: boolean;
  link: string;
  title?: string;
  picto: ReactNode;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <li className={cn("mb-4", className)} title={title}>
      <Link
        to={`/dashboard${link}`}
        className={`line-clamp-1 flex flex-wrap items-center gap-2 text-left text-[13px] leading-4 text-[#878787]  hover:text-[#1C5114] ${
          !isOpen ? "mb-3  justify-center" : "mb-3"
        }`}
        title={children?.toString()}
      >
        {picto} {isOpen && children}
      </Link>
    </li>
  );
};
export default Leftbar;
