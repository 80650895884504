import { ProjectType } from "@/types";
import { create } from "zustand";
// import { createJSONStorage, persist } from 'zustand/middleware';

// Store pour gérer le pourcentage
const usePercentageStore = create<{
  enablePreviewLine?:boolean;
  setEnablePreviewLine: (val: boolean) => void;
  percentage: number;
  setPercentage: (value: number) => void;
  resetPercentage: () => void;
}>((set) => ({
  percentage: 0,
  enablePreviewLine: true,
  setEnablePreviewLine : (value) => set({enablePreviewLine: value}),
  setPercentage: (value) => set({ percentage: value }),
  resetPercentage: () => set({ percentage: 0 }),
}));

type ProjectState = {
  project: ProjectType | null;
  setProject: (project: ProjectType) => void;
};

const useProjectStore = create<ProjectState>((set) => ({
  project: null,
  setProject: (project) => set({ project }),
}));

// Store pour gérer l'état des modals
const useModal = create<{
  modals: { [key: string]: boolean };
  setOpenModal: (modal: { name: string; value: boolean }) => void;
}>((set) => ({
  modals: {},
  setOpenModal: ({ name, value }) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [name]: value,
      },
    })),
}));

// Clé utilisée pour stocker les données dans localStorage
const STORAGE_KEY = "previewPerformance";

const usePreviewPerformance = create<{
  previews: { [key: string]: string };
  setPreviews: (preview: { name: string; value: string }) => void;
}>((set) => ({
  // Initialisation des données depuis localStorage
  previews: JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}"),

  // Mise à jour de l'état et de localStorage
  setPreviews: ({ name, value }) => {
    set((state) => {
      const updatedPreviews = {
        ...state.previews,
        [name]: value,
      };

      // Synchronisation avec localStorage
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPreviews));

      return { previews: updatedPreviews };
    });
  },
}));

const useLeftbar = create<{
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}>((set) => ({
  // Initialisation des données depuis localStorage
  isOpen: JSON.parse(localStorage.getItem("leftbarState") || "false"),

  // Mise à jour de l'état et de localStorage
  setIsOpen: (value) => {
    set(() => {
      // Synchronisation avec localStorage
      localStorage.setItem("leftbarState", JSON.stringify(value));
      return { isOpen: value };
    });
  },
}));

type TabState = {
  tab: "descriptif" | "suivi-perf" | "reporting";
  setTab: (tab: "descriptif" | "suivi-perf" | "reporting") => void;
};

const useTabDashboard = create<TabState>((set) => ({
  tab: "descriptif",
  setTab: (tab) => set({ tab }),
}));

//todo: il faut dégagé le context type à l'avenir et utiliser ça à la place 
// const useTypeStore = create(persist(
//   (set, get) => ({
//     types: [],
//     loading: false,
//     fetchTypes: async () => {
//       if (get().types.length > 0) return; // ✅ Pas de refetch si déjà en cache

//       set({ loading: true });
//       try {
//         const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/project-types`);
//         const data = await response.json();
//         set({ types: data, loading: false });
//       } catch (error) {
//         console.error(error);
//         set({ loading: false });
//       }
//     },
//     clearTypes: () => set({ types: [] }), // 👈 Pour vider le cache si besoin
//   }),
//   {
//     name: 'types-storage',         // 🔒 Clé pour le localStorage
//     storage: createJSONStorage(() => localStorage),        
//   }
// ));


export {
  // useTypeStore,
  useLeftbar,
  useModal,
  usePercentageStore,
  usePreviewPerformance,
  useProjectStore,
  useTabDashboard
};
