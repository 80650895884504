import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { useLeftbar, usePercentageStore } from "@/dashboard/store/store";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { PanelLeftClose, PanelLeftOpen } from "lucide-react";
import { FC, ReactNode } from "react";
import HeaderTop from "./HeaderTop";
import Leftbar from "./Leftbar";

const LayoutDashboard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isOpen, setIsOpen } = useLeftbar();
  const { enablePreviewLine,percentage } = usePercentageStore();

  return (
    <div>
      <ResizablePanelGroup
        className="relative flex max-h-screen flex-wrap"
        direction={"horizontal"}
      >
        <ResizablePanel
          className="max-h-screen overflow-y-auto"
          maxSize={isOpen ? 30 : 3}
          minSize={isOpen ? 16 : 3}
          defaultSize={isOpen ? 16 : 3}
        >
          <Leftbar handleToggleLeftbar={setIsOpen} isOpen={isOpen} />
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel>
          <button
            className={` fixed bottom-5 z-[9999999999] flex size-8 items-center justify-center rounded-md bg-gray-100 text-foreground 
             
            `}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <PanelLeftClose size={18} className="text-[#878787]" />
            ) : (
              <PanelLeftOpen size={18} className="text-[#878787]" />
            )}
          </button>
          <ScrollArea className="max-h-screen overflow-x-auto">
            <div className="  flex-1 pl-10 pr-12 ">
              <HeaderTop />
            </div>
            <div className="mx-8 ">{children}</div>
          </ScrollArea>
          {enablePreviewLine && <div
            className="fixed left-0 top-0 z-[999999] h-1 w-full bg-green-600"
            style={{ width: percentage + "%" }}
          ></div>}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default LayoutDashboard;
