import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Slider } from "@/components/ui/slider";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { Indicator, ProgramType } from "@/types/dashboard";
import { useEffect, useMemo, useState } from "react";
import ProgramCarousel from "../program/ProgramCarousel";
import { calculReporting } from "@/libs/utils";
import ChartItemGlobal from "../project/components/ChartItemGlobal";
import { convertToEuro } from "@/libs/amount";
import StatCardColoredDashboard from "../project/components/StatCardColoredDashboard";

// Programm Carousel
export const CarouselProgram = () => {
  const { data, loading, error, fetchData } = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`,
    "programs",
    1000 * 60 * 60
  );

  const [type, setType] = useState([]);
  const [programs, setPrograms] = useState([]);
  // const [indicator, setIndicator] = useState([]);
  const [indicator, setIndicator] = useState({ env: [], social: [] });
  const [totalFunding, setTotalFunding] = useState({ env: [], social: [] });
  const [fundingByType, setFundingByType] = useState([]);
  const [devises, setDevises] = useState([]);

  // const [project, setProject] = useState([]);
  useEffect(() => {
    // mettre les programme dans useState
    if (data) {
      setPrograms(data);
    }

    
  }, [data]);

  useEffect(() => {
    fetchData(`${import.meta.env.VITE_API_ENDPOINT}/devises`).then((res) => {
      setDevises(res);
    })
  }, []);

  useEffect(() => {
    if(!programs || !devises || programs.length === 0 || devises.length === 0) return;

    localStorage.setItem("devises", JSON.stringify(devises));

    (async function () {// const indicatorTemp = [];
    const indicatorTemp = { env: [], social: [] };
    // const projectTemp = [];
    const FinanceType = [];
    const social: number[] = [0, 0];
    const environnement: number[] = [0, 0];

    let fundingByTypeTemp = [];
    await programs.forEach(async (program) => {
      
      await program.projects.forEach(async (project) => {
        if (project.type.type == 2) {
          await project.desiredFunding.forEach(async (dFunding) => {
            await convertToEuro(dFunding.amount, devises.find((devise) => devise.id === dFunding.devise.id)).then(mt => social[0] += mt);
          });
          await project.obtainedFunding.forEach(async (oFunding) => {
            await convertToEuro(oFunding.amount, devises.find((devise) => devise.id === oFunding.devise.id)).then(mt => social[1] += mt);
          });
        } else {
          await project.desiredFunding.forEach(async (dFunding) => {
            await convertToEuro(dFunding.amount, devises.find((devise) => devise.id === dFunding.devise.id)).then(mt => environnement[0] += mt);
          });
          await project.obtainedFunding.forEach(async (oFunding) => {
            await convertToEuro(oFunding.amount, devises.find((devise) => devise.id === oFunding.devise.id)).then(mt => environnement[1] += mt);
          });
        }

        if(!fundingByTypeTemp.find(ft => {
          return ft.type.id === project.type.id
        })) {
          fundingByTypeTemp.push({
            type: project.type,
            total: 0,
            obtained: 0,
          })
        }

        await fundingByTypeTemp.map(async ft => {
          if(ft.type.id === project.type.id) {
            let df = 0
            await project.desiredFunding.forEach(async (dFunding) => {
              await convertToEuro(dFunding.amount, devises.find((devise) => devise.id === dFunding.devise.id)).then(mt => df += mt);
            });
            
            let of = 0;
            await project.obtainedFunding.forEach(async (oFunding) => {
              await convertToEuro(oFunding.amount, devises.find((devise) => devise.id === oFunding.devise.id)).then(mt => of += mt);
            });

            ft.total += df + of;
            ft.obtained += of;
          }
          return ft;
        })
      });
      
    });

    setFundingByType(fundingByTypeTemp);
    // setProject(projectTemp);
    setType(FinanceType);
    setTotalFunding({
      env: environnement,
      social: social,
    });})()
  }, [programs, devises]);

  if (loading) return <LoadingSpinner />;
  if (error)
    return <p className="text-red-500">Une erreur est survenue : {error}</p>;
  if (!programs?.length)
    return <p className="text-gray-500">Aucun projet trouvé.</p>;
  return (
    <div>
      
      <Carousel
        opts={{
          align: "start",

        }}
        className="m-5 w-auto"
      >
        <CarouselContent>
          {programs.map((program: ProgramType, key: number) => (
            <CarouselItem key={key} className="md:basis-1/5 lg:basis-1/6 xl:basis-[13%]">
              <div className="p-1">
                <ProgramCarousel program={program} />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>

      {/* les onglets projets  */}
      <Tabs defaultValue="env">
        <TabsContent value="env" className="pt-6">
          <Overview
            // indicator={indicator}
            finances={totalFunding.env}
            type={1}
            fundingByType={fundingByType.filter(fbt => fbt.type.type == 1)}
          />
        </TabsContent>
        <TabsContent value="social" className="pt-6">
          <Overview
            // indicator={indicator}
            finances={totalFunding.social}
            type={2}
            fundingByType={fundingByType.filter(fbt => fbt.type.type == 2)}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

// Financement des types de projet
const ProjectFinancement = ({ title, finance }) => {
  const [progress, setProgress] = useState(13);
  useEffect(() => {
    const timer = setTimeout(() => setProgress(finance), 500);
    return () => clearTimeout(timer);
  }, [finance]);
  return (
    <div className="  mx-0  my-4 flex  w-full flex-row items-center justify-between">
      <p className="w-2/3"> {title} :</p>
      <div className=" relative w-1/3">
        <p
          className={`w-full absolute text-center z-[9999999]  font-semibold ${
            finance < 45 ? "text-green-700" : "text-white"
          }`}
        >
          {finance} %
        </p>
        <Progress value={progress} className="h-5 " />
      </div>
    </div>
  );
};
// pourcentage de financement total
const SliderFinance = ({ but, money }) => {
  const [percent, setPercent] = useState(0);
  useMemo(() => {
    setPercent(Math.floor((money / but) * 100));
  }, [but, money]);

  return (
    <div className="relative flex flex-col" id="suivi-financement">
      <div className="grid grid-cols-2 justify-between justify-items-stretch" >
        <p > Fonds demandés / Total projets </p>
        <p className="justify-self-end">
          {money.toLocaleString("fr-FR")} € / {but.toLocaleString("fr-FR")} €
        </p>
      </div>
      <Slider
        className="w-full"
        disabled={true}
        defaultValue={[0]}
        value={[percent]}
        max={100}
        step={1}
      />
    </div>
  );
};

//Onlgets

const Overview = ({ finances, fundingByType, type }) => {
  
  const programReport = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/report/programs`,
    `report`,
    1000 * 60 * 60
  );

  const dataReporting = useMemo(() => {
    if(!programReport.data) return {
      projectIndicators: [], projectTotalIndicators: [], hasData: []
    };
    return calculReporting(programReport.data, type);
  }, [programReport.data, type]);

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <div className=" mr-5 basis-1/2 rounded">
          <div className="rounded-xl bg-[#F7F9FB] p-5">
            <SliderFinance
              but={Math.floor(finances[0] + finances[1])}
              money={Math.floor(finances[0])}
            />
          </div>
          <div className="my-5 rounded-xl bg-[#F7F9FB] p-5" id="repartition-fond">
            <h3 className="p-3"> Déjà financé selon le type de projet</h3>
            <ScrollArea className="h-[210px]  rounded-md ">
              <div
                className={` ${
                  fundingByType.length
                    ? "m-5"
                    : " flex flex-wrap  h-[210px] items-center justify-center"
                } `}
              >
                {fundingByType.length ? (
                  fundingByType.map((fbt, key) => (
                    <ProjectFinancement
                      key={key}
                      title={fbt.type.name}
                      finance={
                        fbt.total !== 0 ? Math.floor((fbt.obtained / fbt.total ) * 100) : 100
                      }
                    />
                  ))
                ) : (
                  <p>aucun projet financé </p>
                )}
              </div>
            </ScrollArea>
          </div>
        </div>
        <div className=" md:size-1/2 pb-5" id="data-reporting">
          <div className={"h-[395px] overflow-auto rounded-xl bg-[#F7F9FB] p-5" + (!dataReporting?.projectTotalIndicators || dataReporting?.projectTotalIndicators.length == 0 ? ' flex items-center justify-center' : '')} style={{
            boxShadow: "inset -4px -5px 19px -14px #ccc",
          }}>
            {dataReporting?.projectTotalIndicators && dataReporting?.projectTotalIndicators.length === 0 && <p className="text-center m-0">Aucune donnée à afficher.</p>}
            {dataReporting?.projectTotalIndicators.filter(ind => ind.type === 1 && ind.indicator.projectOwnerReporting !== null).length > 0 && <StatCardColoredDashboard indicators={dataReporting?.projectTotalIndicators.filter(ind => ind.indicator.projectOwnerReporting !== null)} />}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        {(!programReport.data || !dataReporting) && <div className="pl-5 mb-10"><LoadingSpinner /></div>}
        {programReport.data && dataReporting && programReport.data?.indicators.filter(i => i.projectOwnerReporting !== null && i.id != 2 && dataReporting.hasData.includes(i.id)).map(
          (indicator: Indicator, k: number) =>
             (
              <ChartItemGlobal
                key={k}
                dataReporting={dataReporting.projectIndicators}
                minDate={parseInt(programReport.data.minDate)}
                maxDate={parseInt(programReport.data.maxDate)}
                indicator={indicator}
                className={k % 2 == 0 ? "pr-[0.625rem]" : "pl-[0.625rem]"}
                currentYear={
                  programReport.data.minDate
                }
                maxTco2Year={dataReporting.maxTco2Year}
              />
            )
        )}
      </div>
    </div>
  );
};

// les graphes
