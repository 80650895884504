import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { usePreviewPerformance } from "@/dashboard/store/store";
import {
  addMonths,
  getChartDataMonth,
  getChartDataTrimestre,
  getChartDataYear,
} from "@/libs/utils";
import { Indicator } from "@/types";
import { FC } from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

const chartConfig = {
  prevu: {
    label: "Prévu",
    color: "#59ADC4",
  },
  realise: {
    label: "Réalisé",
    color: "#14AE5C",
  },
  verifie: {
    label: "Vérifié / justifié / certifié",
    color: "#80B533",
  },
} satisfies ChartConfig;

const ChartGlobal: FC<{
  dataReporting: any;
  minDate: any;
  maxDate: any;
  indicator: Indicator;
  currentYear: number;
  maxTco2Year: number;
}> = ({ dataReporting, minDate, maxDate, indicator, currentYear, maxTco2Year }) => {
  const { previews } = usePreviewPerformance();
  const launchDate = new Date(minDate + '-01-01').getFullYear();
  const expectedDuration = (maxDate - minDate) * 12;
  const dateWithAddedMonths = addMonths(new Date(minDate + '-01-01'), expectedDuration);
  const expectedDurationYear = !dateWithAddedMonths
    ? 0
    : dateWithAddedMonths?.getFullYear() == launchDate
    ? 1
    : dateWithAddedMonths.getFullYear() - (launchDate - 1);

  return (
    <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
      <BarChart
        accessibilityLayer
        data={
          getChartDataYear(
            dataReporting,
            indicator,
            expectedDurationYear,
            launchDate,
            minDate,
            indicator.code === "tco2_sequestrees" ? minDate + maxTco2Year : maxDate
          )
        }
        barGap={2}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="legend"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <YAxis tickLine={false} axisLine={false} tickMargin={8} tickCount={4} />
        <ChartTooltip content={<ChartTooltipContent />} />
        <Bar dataKey="prevu" fill={chartConfig.prevu.color} radius={20} />
        <Bar dataKey="realise" fill={chartConfig.realise.color} radius={20} />
        <Bar dataKey="verifie" fill={chartConfig.verifie.color} radius={20} />
      </BarChart>
    </ChartContainer>
  );
};

export default ChartGlobal;
