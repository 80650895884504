export const TYPES_PROJECTS = [
  {
    slug: "agroforesterie",
    name: "Agroforesterie",
    icone: "",
  },
  {
    slug: "permaculture",
    name: "Permaculture",
    icone: "",
  },
  {
    slug: "conversion_agriculture_biologique",
    name: "Conversion à l'agriculture biologique",
    icone: "",
  },
  {
    slug: "jardinage_communautaire",
    name: "Jardinage communautaire",
    icone: "",
  },
  {
    slug: "culture_en_couvert_végétal",
    name: "Culture en couverts végétaux",
    icone: "",
  },
  {
    slug: "gestion_durable_sols_eau",
    name: "Gestion durable des sols et de l'eau",
    icone: "",
  },
  {
    slug: "equipements_infrastructures_transition",
    name: "Equipements et infrastructures de transition",
    icone: "",
  },
  {
    slug: "reforestation",
    name: "Reforestation",
    icone: "",
  },
  {
    slug: "haies",
    name: "Haies",
    icone: "",
  },
  {
    slug: "mangrove_restauration_zones_humides",
    name: "Mangrove et restauration des zones humides",
    icone: "",
  },
  {
    slug: "restauration_recifs_coralliens",
    name: "Restauration des récifs coralliens",
    icone: "",
  },
  {
    slug: "restauration_prairies",
    name: "Restauration des prairies",
    icone: "",
  },
  {
    slug: "restauration_sols",
    name: "Restauration des sols",
    icone: "",
  },
  {
    slug: "captage_eaux_pluie",
    name: "Captage des eaux de pluie",
    icone: "",
  },
  {
    slug: "traitement_eaux_usees_naturelles",
    name: "Traitement des eaux usées naturelles",
    icone: "",
  },
  {
    slug: "projets_conservation_eau",
    name: "Projets de conservation de l'eau",
    icone: "",
  },
  {
    slug: "reserves_naturelles",
    name: "Réserves naturelles",
    icone: "",
  },
  {
    slug: "protection_reintroduction_especes",
    name: "Protection ou réintroduction des espèces",
    icone: "",
  },
  {
    slug: "corridors_ecologiques",
    name: "Corridors écologiques",
    icone: "",
  },
  {
    slug: "ecovillages",
    name: "Ecovillages",
    icone: "",
  },
  {
    slug: "banques_semences_communautaires",
    name: "Banques de semences communautaires",
    icone: "",
  },
  {
    slug: "education_environnement",
    name: "Education à l'environnement",
    icone: "",
  },
  {
    slug: "initiatives_justice_alimentaire",
    name: "Initiatives de justice alimentaire",
    icone: "",
  },
] as const;

export const projectStatus = {
  NotStarted: 0,
  InProgress: 1,
  AwaitingValidation: 2,
  Validated: 3,
  Rejected: 4,
};

export const langs = ["fr", "en"];

export const listAllMonths = [
  "Jan",
  "Fév",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Aoû",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
];

export const units = [
  {
    name: "Monétaire",
    description: "Euro (€), Dollar ($), Livre sterling (£), Yen (¥), Franc CFA",
    choices: [
      { name: "Euro", value: "EUR" },
      { name: "Dollar", value: "USD" },
      { name: "Livre sterling", value: "GBP" },
      { name: "Yen", value: "JPY" },
      { name: "Franc CFA", value: "XOF" },
    ],
  },
  {
    name: "Volume",
    description:
      "Litre (L), Millilitre (mL), Mètre cube (m³), Centimètre cube (cm³)",
    choices: [
      { name: "Millilitre", value: "mL" },
      { name: "Litre", value: "l" },
      { name: "Centimètre cube", value: "cm³" },
      { name: "Mètre cube", value: "m³" },
    ],
  },
  {
    name: "Poids / Masse",
    description:
      "Kilogramme (kg), Gramme (g), Tonne (t), Livre (lb), Once (oz)",
    choices: [
      { name: "Gramme", value: "g" },
      { name: "Kilogramme", value: "kg" },
      { name: "Tonne", value: "t" },
      { name: "Livre", value: "lb" },
      { name: "Once", value: "oz" },
    ],
  },
  {
    name: "Longueur",
    description:
      "Mètre (m), Centimètre (cm), Millimètre (mm), Kilomètre (km), Pouce (in), Pied (ft), Mile (mi)",
    choices: [
      { name: "Millimètre", value: "mm" },
      { name: "Centimètre", value: "cm" },
      { name: "Mètre", value: "m" },
      { name: "Kilomètre", value: "km" },
      { name: "Pouce", value: "in" },
      { name: "Pied", value: "ft" },
      { name: "Mile", value: "mi" },
    ],
  },
  {
    name: "Surface",
    description:
      "Mètre carré (m²), Centimètre carré (cm²), Kilomètre carré (km²), Hectare (ha), Acre",
    choices: [
      { name: "Centimètre carré", value: "cm²" },
      { name: "Mètre carré", value: "m²" },
      { name: "Kilomètre carré", value: "km²" },
      { name: "Hectare", value: "ha" },
      { name: "Acre", value: "acre" },
    ],
  },
  {
    name: "Temps",
    description:
      "Seconde (s), Minute (min), Heure (h), Jour (d), Semaine, Mois, Année",
    choices: [
      { name: "Seconde", value: "s" },
      { name: "Minute", value: "min" },
      { name: "Heure", value: "h" },
      { name: "Jour", value: "d" },
      { name: "Semaine", value: "week" },
      { name: "Mois", value: "month" },
      { name: "Année", value: "year" },
    ],
  },
  {
    name: "Vitesse",
    description:
      "Mètre par seconde (m/s), Kilomètre par heure (km/h), Mile par heure (mph), Nœud (kt)",
    choices: [
      { name: "Mètre par seconde", value: "m/s" },
      { name: "Kilomètre par heure", value: "km/h" },
      { name: "Mile par heure", value: "mph" },
      { name: "Nœud", value: "kt" },
    ],
  },
  {
    name: "Température",
    description: "Celsius (°C), Fahrenheit (°F), Kelvin (K)",
    choices: [
      { name: "Celsius", value: "C" },
      { name: "Fahrenheit", value: "F" },
      { name: "Kelvin", value: "K" },
    ],
  },
  {
    name: "Énergie",
    description:
      "Joule (J), Kilojoule (kJ), Calorie (cal), Kilocalorie (kcal), Kilowatt-heure (kWh)",
    choices: [
      { name: "Joule", value: "J" },
      { name: "Kilojoule", value: "kJ" },
      { name: "Calorie", value: "cal" },
      { name: "Kilocalorie", value: "kcal" },
      { name: "Kilowatt-heure", value: "kWh" },
    ],
  },
  {
    name: "Pression",
    description:
      "Pascal (Pa), Bar, Atmosphère (atm), Millimètre de mercure (mmHg), PSI",
    choices: [
      { name: "Pascal", value: "Pa" },
      { name: "Bar", value: "bar" },
      { name: "Atmosphère", value: "atm" },
      { name: "Millimètre de mercure", value: "mmHg" },
      { name: "PSI", value: "psi" },
    ],
  },
  {
    name: "Force",
    description: "Newton (N), Kilonewton (kN), Dyne (dyn)",
    choices: [
      { name: "Newton", value: "N" },
      { name: "Kilonewton", value: "kN" },
      { name: "Dyne", value: "dyn" },
    ],
  },
  {
    name: "Puissance",
    description: "Watt (W), Kilowatt (kW), Mégawatt (MW), Cheval-vapeur (CV)",
    choices: [
      { name: "Watt", value: "W" },
      { name: "Kilowatt", value: "kW" },
      { name: "Mégawatt", value: "MW" },
      { name: "Cheval-vapeur", value: "CV" },
    ],
  },
  {
    name: "Fréquence",
    description:
      "Hertz (Hz), Kilohertz (kHz), Mégahertz (MHz), Gigahertz (GHz)",
    choices: [
      { name: "Hertz", value: "Hz" },
      { name: "Kilohertz", value: "kHz" },
      { name: "Mégahertz", value: "MHz" },
      { name: "Gigahertz", value: "GHz" },
    ],
  },
  {
    name: "Angle",
    description: "Degré (°), Radian (rad), Grade",
    choices: [
      { name: "Degré", value: "deg" },
      { name: "Radian", value: "rad" },
      { name: "Grade", value: "gon" },
    ],
  },
  {
    name: "Nombre",
    description:
      "Un nombre sans unité, comme un nombre d'arbres ou de personnes.",
    choices: [
      { name: "Nombre", value: "Nombre" },
      { name: "Pourcentage", value: "%" },
      { name: "Ratio", value: "ratio" },
      { name: "Coefficient", value: "coefficient" },
    ],
  },
];

export const esrss = [
  "S1-1",
  "S1-2",
  "S1-3",
  "S2-1",
  "S2-2",
  "S3",
  "S3-1",
  "S3-2",
  "S3-3",
  "S3-4",
  "S4-1",
  "S4-2",
  "S4-3",
  "E1-1",
  "E2-1",
  "E2-2",
  "E3-1",
  "E3-2",
  "E3-4",
  "E5-1",
];
